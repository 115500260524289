import React, { useState } from 'react';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  Button,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  CircularProgress,
  styled,
  Alert,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import theme from 'theme';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PersonIcon from '@mui/icons-material/Person';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LogoutIcon from '@mui/icons-material/Logout';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SecurityIcon from '@mui/icons-material/Security';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ArticleIcon from '@mui/icons-material/Article';
import HotelIcon from '@mui/icons-material/Hotel';
import LinkIcon from '@mui/icons-material/Link';
import SpaceIcon from '@mui/icons-material/FolderShared';
import InventoryIcon from '@mui/icons-material/Inventory';
import PaletteIcon from '@mui/icons-material/Palette';

import logo from 'assets/images/logo.png';
import AppBar from 'components/AppBar';
import Drawer from 'components/Drawer';

import i18next from 'i18next';

import { resetUser, setUser } from 'store/userSlice';
import { userSelector, user2IUser, canEditAdminSpaceId } from 'helper/security';
import { buildHotelSelectionOptions } from 'components/security/HotelSelectionInput';
import { buildMenuItemsFromOptions } from 'components/form/FormInputDropdown';
import { setFilter } from 'store/filterSlice';
import { filterSelector } from 'helper/filter';

import { ME_QUERY, LOGOUT_MUTATION } from './login';
import { EHotelListerStatusType, EHotelWidgetStatusType, MeQueryQuery } from '__generated__/graphql';
import moment from 'moment';
import SimpleDialog from 'components/dialogs/Dialog';
import { Task, WarningAmber } from '@mui/icons-material';
import { HOTEL_LIST_STATUS_QUERY } from 'pages/settings/gql';
import { isProductionRelease } from 'helper/deployment';
import { useDocumentTitle } from 'helper/usedocumenttitle';

type TListSpacesElement = MeQueryQuery['listSpaces'][0];
type TListHotelsElement = MeQueryQuery['listHotels'][0];

interface LinkButtonProps {
  route: string;
  label: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  showWarning?: boolean;
}

const LinkButtonContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
  '& > a': {
    textDecoration: 'none',
    color: disabled ? `${theme.palette.disabled} !important` : theme.palette.primary.main,
    fontWeight: 'bold',
  },
  '& svg': {
    color: disabled ? theme.palette.disabled : theme.palette.primary.main,
  },
}));

const LinkButton = ({ route, label, icon, disabled, showWarning }: LinkButtonProps) => {
  const location  = useLocation();

  const CustomLink = ({ children }: any) =>
    disabled ? (
      <>{children}</>
    ) : (
      <Link to={route} style={{ textDecoration: 'none' }}>
        {children}
      </Link>
    );

  return (
    <LinkButtonContainer disabled={disabled}>
      <CustomLink>
        <ListItemButton disabled={disabled} sx={{backgroundColor: location.pathname.includes(route) ? '#f3f3f3' : 'transparent' }}>
          <ListItemIcon>{icon || <DashboardIcon />}</ListItemIcon>
          <ListItemText primary={label} />
          {showWarning && <WarningAmber color="warning" />}
        </ListItemButton>
      </CustomLink>
    </LinkButtonContainer>
  );
};

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const documentTitle = useDocumentTitle();
  const user = userSelector();
  const filter = filterSelector();

  const [logoutMutation] = useMutation(LOGOUT_MUTATION);

  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [showVersion, setShowVersion] = useState(false);

  const [spaces, setSpaces] = useState([] as TListSpacesElement[]);
  const [hotels, setHotels] = useState([] as TListHotelsElement[]);

  const {
    loading,
    error,
    data: statusData,
  } = useQuery(HOTEL_LIST_STATUS_QUERY, {
    variables: {
      spaceId: (filter && filter.spaceId) || null,
    },
  });

  const filterText = filter && (filter.spaceId || filter.hotelId) ?
    (filter.hotelId ? (
      <>
        <HotelIcon /> {hotels.find(h => h.id === filter.hotelId)?.name}
      </>
    ) : (
      <>
        <SpaceIcon /> {spaces.find(s => s.id === filter.spaceId)?.name}
      </>
    )) : null

  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<null | HTMLElement>(null);
  const filterMenuOpen = Boolean(filterMenuAnchorEl);
  const handleFilterMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuAnchorEl(event.currentTarget);
  };
  const handleFilterMenuClose = () => {
    setFilterMenuAnchorEl(null);
  };

  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
  const userMenuOpen = Boolean(userMenuAnchorEl);
  const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserMenuAnchorEl(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logoutMutation();
    } finally {
      dispatch(resetUser());
      window.location.href = '/login';
    }
  };

  useQuery(ME_QUERY, {
    fetchPolicy: 'network-only',
    onError: err => {
      dispatch(resetUser());
      navigate('/login');
    },
    onCompleted: data => {
      if (data.me) {
        dispatch(setUser(user2IUser(data.me, data.listSpaces, data.listHotels)));
        setSpaces(data.listSpaces);
        setHotels(data.listHotels);
      } else {
        dispatch(resetUser());
        navigate('/login');
      }
    },
  });

  const filterSelectOptions = buildHotelSelectionOptions(spaces, hotels, true)

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            {documentTitle || 'Seminargo'}
          </Typography>
          {/*
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          */}
          {filterSelectOptions.length > 0 && <>
            <Button onClick={handleFilterMenuClick} color="secondary" variant="text" endIcon={<ArrowDropDownIcon />}>
              {filterText || <em>{i18next.t(user?.isSingleSpace ? 'main-filter-select-hotel' : 'main-filter-select')}</em>}
            </Button>
            {filterMenuOpen && (
              <Menu anchorEl={filterMenuAnchorEl} open={open} onClose={handleFilterMenuClose}>
                {filterText && (
                  <MenuItem
                    onClick={() => {
                      dispatch(setFilter({ spaceId: null, hotelId: null }));
                      handleFilterMenuClose();
                    }}
                  >
                    <em>{i18next.t('main-filter-empty')}</em>
                  </MenuItem>
                )}
                {buildMenuItemsFromOptions(filterSelectOptions, (filter: any) => {
                  dispatch(setFilter(filter));
                  handleFilterMenuClose();
                })}
              </Menu>
            )}
          </>}
          <Button onClick={handleUserMenuClick} color="secondary" variant="text" endIcon={<ArrowDropDownIcon />}>
            {user && (
              <>
                {user.isAdmin ? <SupervisorAccountIcon /> : <PersonIcon />} {user.name}
              </>
            )}
            {!user && '?'}
          </Button>
          {userMenuOpen && (
            <Menu anchorEl={userMenuAnchorEl} open={open} onClose={handleUserMenuClose}>
              <MenuItem onClick={() => navigate('/settings/security/me')}>
                <PersonIcon /> {i18next.t('main-usermenu-account')}
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <LogoutIcon /> {i18next.t('main-usermenu-logout')}
              </MenuItem>
            </Menu>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <img src={logo} height="40" alt="seminargo Logo" onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
          <IconButton color="secondary" onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        {user && (
          <>
            <Divider />
            <List component="nav">
              {user.isRoot && (
                <>
              <LinkButton route="/tasks" label={i18next.t('sidebar-tasks')} icon={<Task />} />
              <Divider sx={{ my: 1 }} />
              </>
              )}
              <LinkButton route="/offers/widget" label={i18next.t('sidebar-widgetoffers')} icon={<LocalOfferIcon />} />
              <LinkButton route="/offers/lister" label={i18next.t('sidebar-listeroffers')} icon={<LocalOfferIcon />} />
              <Divider sx={{ my: 1 }} />
              <ListSubheader component="div" inset>
                {i18next.t('sidebar-catalog')}
              </ListSubheader>
              <LinkButton route="/products" label={i18next.t('sidebar-products')} icon={<ShoppingCartIcon />} />
              <LinkButton route="/pricelists" label={i18next.t('sidebar-pricelists')} icon={<CreditCardIcon />} />
              <LinkButton route="/servicetypes" label={i18next.t('sidebar-product-bundles')} icon={<InventoryIcon />} />
              <LinkButton route="/availability" label={i18next.t('sidebar-availability')} icon={<EventAvailableIcon />} />
              <Divider sx={{ my: 1 }} />
              <ListSubheader component="div" inset>
                {i18next.t('sidebar-content')}
              </ListSubheader>
              <LinkButton route="/content/texts" label={i18next.t('sidebar-text')} icon={<TextFieldsIcon />} />
              <LinkButton route="/content/media" label={i18next.t('sidebar-media')} icon={<PhotoLibraryIcon />} />
              <LinkButton route="/content/templates/emailtemplates" label={i18next.t('sidebar-templates')} icon={<ArticleIcon />} />
              <Divider sx={{ my: 1 }} />
              <ListSubheader component="div" inset>
                {i18next.t('sidebar-settings')}
              </ListSubheader>
              {user.isRoot && (<LinkButton route="/clients" label={i18next.t('sidebar-clients')} icon={<SupervisorAccountIcon />} />)}
              <LinkButton
                route="/settings/hotels"
                label={i18next.t('sidebar-hotels')}
                icon={<HotelIcon />}
                showWarning={statusData?.listHotels && statusData.listHotels.filter(h => (h.widgetStatus !== EHotelWidgetStatusType.READY && h.widgetStatus !== EHotelWidgetStatusType.INACTIVE) || (h.listerStatus !== EHotelListerStatusType.READY && h.listerStatus !== EHotelListerStatusType.INACTIVE)).length > 0}
              />
              <LinkButton route="/settings/security" label={i18next.t('sidebar-security')} icon={<SecurityIcon />} />
              {!isProductionRelease() && <LinkButton route="/settings/integration" label={i18next.t('sidebar-integration')} icon={<LinkIcon />} />}
              <LinkButton
                disabled={!((filter && filter.spaceId && canEditAdminSpaceId(user, filter.spaceId)) || ((!filter || !filter.spaceId) && user.isSingleAdminSpace))}
                route="/settings/space"
                label={i18next.t('sidebar-space')}
                icon={<PaletteIcon />}
              />
              <Divider sx={{ my: 1 }} />
              <LinkButton route="https://www.seminargo.com/faq" label={i18next.t('sidebar-help')} icon={<HelpCenterIcon />} />
            </List>
            <div style={{ position: 'absolute', bottom: 0, width: '100%', padding: 10, fontSize: 11, color: '#999' }}>
              <a href="https://www.seminargo.com" target="_blank">
                seminargo
              </a>{' '}
              &copy; {moment().year()}{' '}
              {process.env.REACT_APP_BRANCH && (
                <>
                  | Version:{' '}
                  <a href="#" style={{ cursor: 'pointer' }} onClick={() => setShowVersion(true)}>
                    {process.env.REACT_APP_BRANCH}
                  </a>
                </>
              )}
            </div>
            <SimpleDialog
              open={showVersion}
              title={i18next.t('sem-version-dialog-title')}
              setOpen={setShowVersion}
              onOk={() => setShowVersion(false)}
            >
              <strong>Branch-Version: </strong>
              {process.env.REACT_APP_BRANCH}
              <br />
              <strong>Build-Date: </strong>
              {process.env.REACT_APP_BUILD_DATE}
              <br />
              <strong>Commit-Hash: </strong>
              {process.env.REACT_APP_COMMIT_HASH}
              <br />
            </SimpleDialog>
          </>
        )}
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: theme => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        {user && <Outlet />}
        {!user && <CircularProgress />}
      </Box>
    </Box>
  );
}
