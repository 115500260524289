import _ from 'lodash';

export type EAM_EventProductLookupCodes = "TAGESPAUSCHALE" | "HALBTAGESPAUSCHALE" | "ADDON_LUNCH" | "ADDON_DINNER" | "ROOM_EZ" | "ROOM_DZ";
export type EAM_EventRoomType = "ROOM_DOUBLE" | "ROOM_SINGLE" | "ROOM_NONE";

interface IAttendance {
  morning?: boolean | null;
  forenoon?: boolean | null;
  lunch?: boolean | null;
  afternoon?: boolean | null;
  dinner?: boolean | null;
  room?: EAM_EventRoomType | null;
}
interface IShow {
  morningShow?: boolean | null;
  forenoonShow?: boolean | null;
  lunchShow?: boolean | null;
  afternoonShow?: boolean | null;
  dinnerShow?: boolean | null;
  roomShow?: boolean | null;
}

interface IDirectPay {
  serviceDirectPay?: boolean | null;
  roomDirectPay?: boolean | null;
  extraDirectPay?: boolean | null;
  parkingDirectPay?: boolean | null;
}

export const isValidAttendanceLookupCode = (code: string): code is EAM_EventProductLookupCodes => {
  return (
    code === "TAGESPAUSCHALE" ||
    code === "HALBTAGESPAUSCHALE" ||
    code === "ADDON_LUNCH" ||
    code === "ADDON_DINNER" ||
    code === "ROOM_EZ" ||
    code === "ROOM_DZ"
  );
}

export const mapAttendanceRegistered = (lookupCode: EAM_EventProductLookupCodes, aday: IAttendance) => {
  if (lookupCode === 'TAGESPAUSCHALE') {
    return aday.forenoon && aday.afternoon
  }
  if (lookupCode === 'HALBTAGESPAUSCHALE') {
    return (aday.forenoon || aday.afternoon) && !(aday.forenoon && aday.afternoon)
  }
  if (lookupCode === 'ADDON_LUNCH') {
    return aday.lunch
  }
  if (lookupCode === 'ADDON_DINNER') {
    return aday.dinner
  }
  if (lookupCode === 'ROOM_DZ') {
    return aday.room === 'ROOM_DOUBLE'
  }
  if (lookupCode === 'ROOM_EZ') {
    return aday.room === 'ROOM_SINGLE'
  }
  return false
}

export const mapAttendanceShow = (lookupCode: EAM_EventProductLookupCodes, aday: IShow) => {
  const _cf = (flag: string) => _.isBoolean((aday as any)[flag]) ? !!(aday as any)[flag] : true

  if (lookupCode === 'TAGESPAUSCHALE') {
    return _cf('forenoonShow') && _cf('afternoonShow')
  }
  if (lookupCode === 'HALBTAGESPAUSCHALE') {
    return (_cf('forenoonShow') || _cf('afternoonShow')) && !(_cf('forenoonShow') && _cf('afternoonShow'))
  }
  if (lookupCode === 'ADDON_LUNCH') {
    return _cf('lunchShow')
  }
  if (lookupCode === 'ADDON_DINNER') {
    return _cf('dinnerShow')
  }
  if (lookupCode === 'ROOM_DZ' || lookupCode === 'ROOM_EZ') {
    return _cf('roomShow')
  }
  return true
}

export const mapAttendanceDirectPay = (lookupCode: EAM_EventProductLookupCodes, a: IDirectPay, aday: IDirectPay) => {
  const _cf = (flag: string) => _.isBoolean((aday as any)[flag]) ? !!(aday as any)[flag] : _.isBoolean((a as any)[flag]) ? !!(a as any)[flag] : false

  if (lookupCode === 'TAGESPAUSCHALE' || lookupCode === 'HALBTAGESPAUSCHALE') {
    return _cf('serviceDirectPay')
  }
  if (lookupCode === 'ADDON_LUNCH' || lookupCode === 'ADDON_DINNER') {
    return _cf('serviceDirectPay')
  }
  if (lookupCode === 'ROOM_DZ' || lookupCode === 'ROOM_EZ') {
    return _cf('roomDirectPay')
  }
  return false
}


export const mapShowAttendance = (lookupCode: EAM_EventProductLookupCodes, shows: boolean[], attendances: IShow[]) => {
  return shows.map((show, index) => {
    const attendance = attendances[index]
    if (lookupCode === 'TAGESPAUSCHALE') {
      attendance.forenoonShow = show
      attendance.afternoonShow = show
    } else if (lookupCode === 'HALBTAGESPAUSCHALE') {
      attendance.forenoonShow = show
    } else if (lookupCode === 'ADDON_LUNCH') {
      attendance.lunchShow = show
    } else if (lookupCode === 'ADDON_DINNER') {
      attendance.dinnerShow = show
    } else if (lookupCode === 'ROOM_DZ' || lookupCode === 'ROOM_EZ') {
      attendance.roomShow = show
    }
    return attendance
  })
}