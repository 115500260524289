import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button, Grid, CircularProgress, Divider, Typography, TextField, Box, InputAdornment, IconButton, Accordion, AccordionDetails, AccordionSummary, Select, MenuItem, ListSubheader } from '@mui/material';

import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import UploadIcon from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import DeleteIcon from '@mui/icons-material/Delete';

import { dispatchException, dispatchMessage } from 'helper/snackbar';

import { CustomTabsControlled } from 'components/Tabs';

import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';

import {
  EVICT_INVOICE_QUERIES,
  INVOICE_APPROVE_HOTEL_MUTATION,
  INVOICE_VIEW_QUERY,
  INVOICE_VIEW_MEDIA_QUERY,
  REFETCH_INVOICE_QUERIES,
} from './gql';

import { EAMRoomType, EContentProductDetailPosition, EOfferSource, EOfferVersionContentBlockType, EOfferVersionStatus, EOfferVersionType, EProductLookupCode, ViewInvoiceQuery, ViewWlOfferQuery, ViewWlOfferVersionQuery, WLInvoiceAttendanceInput, WLOfferListOutput, WLOfferListViewFragment, WLOfferVersionDiffOutputFragment, WLOfferVersionListOutput, WLOfferVersionListViewFragment } from '__generated__/graphql';

import { formatDate } from 'components/DateTime';

import { QuickPriceCalculator, QuickPriceCalculatorType } from '../../semshared/pricelist/quickprice';

import { RedirectError } from 'pages/error';
import { formatDocumentTitle } from 'helper/usedocumenttitle';
import yup from 'validation';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatPrice } from 'components/Price';
import { calculateTotals, formatOfferDay } from 'semshared/offer/offer';
import { FormInputText } from 'components/form/FormInputText';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormInputCurrency } from 'components/form/FormInputCurrency';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { FormInputDropdown } from 'components/form/FormInputDropdown';
import FileUploadButton from 'components/FileUploadButton';
import { ProductTextSelector, ProductTextSelectorType } from 'semshared/pricelist/producttextselector';
import InformationDialog from 'components/dialogs/InformationDialog';
import { getFullBackendUrl } from 'helper/download';
import { formatPercentage } from 'components/Percentage';
import { EAM_EventProductLookupCodes, isValidAttendanceLookupCode, mapAttendanceDirectPay, mapAttendanceRegistered, mapAttendanceShow, mapShowAttendance } from 'semshared/offer/invoice';

interface InvoiceProps {
  id: number;
}

interface InvoiceFormProps {
  offer: NonNullable<ViewInvoiceQuery['viewWlOffer']>,
  version: NonNullable<ViewInvoiceQuery['viewWlOfferInvoiceVersion']>,
  event: ViewInvoiceQuery['viewWlOfferInvoiceEvent'],
  quickPriceCalculator: QuickPriceCalculatorType,
  productTexts: ProductTextSelectorType
}

const taxesSchema = yup.object().shape({
  taxRateId: yup.number().required(),
  name: yup.string().required(),
  rate: yup.number().required(),
  price: yup.number().required(),
});

const attendanceSchema = yup.object().shape({
  profileId: yup.number().required(),
  profileName: yup.string().required(),
  day: yup.number().required(),
  directPay: yup.boolean().required(),
  show: yup.boolean().required()
});

const componentsSchema = yup.object().shape({
  taxTypeId: yup.number().required(),
  name: yup.string().required(),
  price: yup.number().required().label(i18next.t('invoice-lineitem-priceitem')),
});

const lineItemSchema = yup.object().shape({
  lineItemId: yup.number().required(),
  day: yup.number().required(),
  count: yup.number().required().label(i18next.t('invoice-lineitem-count')),
  sku: yup.string().nullable(),
  header: yup.string().required(),
  priceItem: yup.number().required(),
  priceGross: yup.number().required(),
  priceNet: yup.number().required(),
  components: yup.array().required().of(componentsSchema),
  taxes: yup.array().required().of(taxesSchema),
  media: yup.object().nullable().shape({
    id: yup.number().required(),
    name: yup.string().required(),
    mimeType: yup.string().required(),
    base64: yup.string().nullable()
  }),
  hasAttendances: yup.boolean().required(),
  attendances: yup.array().required().of(attendanceSchema),
});

const daySchema = yup.object().shape({
  contentBlockId: yup.number().required(),
  day: yup.number().required(),
  lineItems: yup.array().required().of(lineItemSchema),
  accepted: yup.boolean().required(),
  expanded: yup.boolean().required()
});

const cancellationSchema = yup.object().shape({
  contentBlockId: yup.number().required(),
  daysToEvent: yup.number().required(),
  rate: yup.number().required(),
  lineItems: yup.array().required().of(lineItemSchema)
});

const validationSchema = yup.object().shape({
  days: yup.array().required().min(1).of(daySchema),
  extraLineItems: yup.array().required().of(lineItemSchema),
  cancellations: yup.array().required().of(cancellationSchema),
  agreedCancellationWaiveNet: yup.number().nullable(),
  manualCancellationWaiveNet: yup.number().nullable(),
  totalPriceNet: yup.number().required(),
  totalPriceGross: yup.number().required(),
  totalTaxes: yup.array().required().of(taxesSchema),
});

export type InvoiceFormType = yup.InferType<typeof validationSchema>;
export type DayFormType = yup.InferType<typeof daySchema>;
export type LineItemFormType = yup.InferType<typeof lineItemSchema>;
export type MediaFormType = NonNullable<LineItemFormType['media']>;
export type AttendanceFormType = yup.InferType<typeof attendanceSchema>;
export type TaxesType = yup.InferType<typeof taxesSchema>;

interface InvoiceFormSubProps extends InvoiceFormProps {
  form: InvoiceFormType
}
interface InvoiceFormDayProps extends InvoiceFormSubProps {
  dayIndex: number;
  disabled: boolean;
  setHasAllAccepted: (a: boolean) => void;
  lineItemAttendanceChanged: (dayIndex: number, lineItemIndex: number, attendanceIndex: number, show: boolean) => void;
}
interface InvoiceFormLineItemProps extends InvoiceFormDayProps {
  lineItemIndex: number;
}
interface InvoiceFormLineItemAttendanceProps extends InvoiceFormLineItemProps {
  attendanceIndex: number;
}

function InvoiceFormDay(props: InvoiceFormDayProps) {
  const { dayIndex, version, disabled } = props
  const { control, watch, getValues, setValue } = useFormContext<InvoiceFormType>();

  const dayWatch = watch(`days.${dayIndex}.day`);
  const dayDate = moment(version.startDate).add(dayWatch, 'days').toDate();

  const acceptedWatch = watch(`days.${dayIndex}.accepted`)

  useEffect(() => {
    const daysWatch = watch('days')
    const na = daysWatch.find(d => !d.accepted)
    props.setHasAllAccepted(!na)
  }, [acceptedWatch])

  useEffect(() => {
    if (disabled) return
    if (!acceptedWatch) return
    
    setValue(`days.${dayIndex}.expanded`, false)
    if (dayIndex < watch('days').length - 1) {
      setValue(`days.${dayIndex + 1}.expanded`, true)
    }
  }, [acceptedWatch])

  const { fields: lineItemsFields } = useFieldArray({
    control,
    name: `days.${dayIndex}.lineItems`,
  });

  return <Accordion expanded={watch(`days.${dayIndex}.expanded`)} onChange={(ev, expanded) => setValue(`days.${dayIndex}.expanded`, expanded)}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>
        {formatOfferDay(dayWatch!)} - {formatDate(dayDate)}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={1}>
        {lineItemsFields.map((li, index) => (
          <InvoiceFormLineItem key={index} lineItemIndex={index} {...props} />
        ))}
      </Grid>
      {!disabled &&
        <Grid container>
          <Grid item xs={12} justifyItems={'end'}>
            <FormInputCheckbox name={`days.${dayIndex}.accepted`} label={i18next.t('invoice-day-accepted')} control={control} />
          </Grid>
        </Grid>
      }
    </AccordionDetails>
  </Accordion>
}

function InvoiceFormLineItem(props: InvoiceFormLineItemProps) {
  const { dayIndex, lineItemIndex, version, disabled } = props
  const { control, watch, getValues } = useFormContext<InvoiceFormType>();

  const skuWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.sku`);
  const headerWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.header`);
  const hasAttendancesWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.hasAttendances`);

  const { fields: attendancesFields } = useFieldArray({
    control,
    name: `days.${dayIndex}.lineItems.${lineItemIndex}.attendances`,
  });

  return <>
    <Grid item sm={1}>
      <FormInputNumber label={i18next.t('invoice-lineitem-count')} name={`days.${dayIndex}.lineItems.${lineItemIndex}.count`} control={control} disabled />
    </Grid>
    <Grid item sm={9}>
      {hasAttendancesWatch && <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            {headerWatch} ({skuWatch})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {attendancesFields.map((a, index) => (
              <InvoiceFormLineItemAttendance key={index} attendanceIndex={index} {...props} />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>}
      {!hasAttendancesWatch &&
        <Typography>
          {headerWatch} ({skuWatch})
        </Typography>
      }
    </Grid>
    <Grid item sm={1}>
      <FormInputCurrency name={`days.${dayIndex}.lineItems.${lineItemIndex}.priceNet`} label={i18next.t('invoice-lineitem-net')} currency={version.priceList.currency} control={control} disabled />
    </Grid>
    <Grid item sm={1}>
      <FormInputCurrency name={`days.${dayIndex}.lineItems.${lineItemIndex}.priceGross`} label={i18next.t('invoice-lineitem-gross')} currency={version.priceList.currency} control={control} disabled />
    </Grid>
  </>
}

function InvoiceFormLineItemAttendance({ dayIndex, lineItemIndex, attendanceIndex, disabled, lineItemAttendanceChanged }: InvoiceFormLineItemAttendanceProps) {
  const { control, watch } = useFormContext<InvoiceFormType>();

  const showWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.attendances.${attendanceIndex}.show`);
  
  useEffect(() => {
    if (disabled) return;
    lineItemAttendanceChanged(dayIndex, lineItemIndex, attendanceIndex, showWatch)
  }, [showWatch])

  return <>
    <Grid item sm={6}>
      <FormInputText name={`days.${dayIndex}.lineItems.${lineItemIndex}.attendances.${attendanceIndex}.profileName`} control={control} disabled />
    </Grid>
    <Grid item sm={3}>
      <FormInputCheckbox name={`days.${dayIndex}.lineItems.${lineItemIndex}.attendances.${attendanceIndex}.show`} label={i18next.t('invoice-lineitem-attendance-show')} control={control} disabled={disabled} />
    </Grid>
    <Grid item sm={3}>
      <FormInputCheckbox name={`days.${dayIndex}.lineItems.${lineItemIndex}.attendances.${attendanceIndex}.directPay`} label={i18next.t('invoice-lineitem-attendance-directpay')} control={control} disabled/>
    </Grid>
  </>
}
interface InvoiceFormExtraLineItemProps extends InvoiceFormSubProps {
  extraLineItemIndex: number;
  disabled: boolean;
  isPricesNet: boolean;
  extraLineItemRemove: (index: number) => void;
}
interface InvoiceFormExtraLineItemComponentProps extends InvoiceFormExtraLineItemProps {
  extraLineItemComponentIndex: number;
}

function InvoiceFormExtraLineItemListener(props: { extraLineItemIndex: number, extraLineItemChanged: (index: number) => void }) {
  const { watch, control } = useFormContext<InvoiceFormType>();

  const countWatch = watch(`extraLineItems.${props.extraLineItemIndex}.count`)
  const priceWatch = watch(`extraLineItems.${props.extraLineItemIndex}.priceItem`)
  const dayWatch = watch(`extraLineItems.${props.extraLineItemIndex}.day`)

  const { fields: componentsFields } = useFieldArray({
    control,
    name: `extraLineItems.${props.extraLineItemIndex}.components`,
  });

  useEffect(() => {
    props.extraLineItemChanged(props.extraLineItemIndex)
  }, [countWatch, priceWatch, dayWatch])

  return <>
    {componentsFields.map((c, index) => <InvoiceFormExtraLineItemComponentListener key={index} extraLineItemComponentIndex={index} {...props} />)}
  </>
}
function InvoiceFormExtraLineItemComponentListener(props: { extraLineItemIndex: number, extraLineItemComponentIndex: number, extraLineItemChanged: (index: number) => void }) {
  const { watch } = useFormContext<InvoiceFormType>();

  const priceWatch = watch(`extraLineItems.${props.extraLineItemIndex}.components.${props.extraLineItemComponentIndex}.price`)
  useEffect(() => {
    props.extraLineItemChanged(props.extraLineItemIndex)
  }, [priceWatch])
  return null
}

function InvoiceFormExtraLineItem(props: InvoiceFormExtraLineItemProps) {
  const { extraLineItemIndex, version, disabled, isPricesNet, extraLineItemRemove } = props
  const { control, watch } = useFormContext<InvoiceFormType>();

  const [mediaQuery, { loading: mediaLoading }] = useLazyQuery(INVOICE_VIEW_MEDIA_QUERY);

  const [mediaBase64, setMediaBase64] = useState<string>()

  const daysWatch = watch('days')
  const idWatch = watch(`extraLineItems.${extraLineItemIndex}.lineItemId`);
  const mediaWatch = watch(`extraLineItems.${extraLineItemIndex}.media`);
  const componentsWatch = watch(`extraLineItems.${extraLineItemIndex}.components`);

  const { fields: componentsFields } = useFieldArray({
    control,
    name: `extraLineItems.${extraLineItemIndex}.components`,
  });

  return <>
    <Grid item sm={2}>
      <FormInputDropdown
        name={`extraLineItems.${extraLineItemIndex}.day`}
        label={i18next.t('invoice-lineitem-day')}
        control={control}
        options={_.uniq([-1, ...daysWatch.map(d => d.day)]).sort().map(day => ({
          value: day,
          label: <>{formatOfferDay(day)} - {formatDate(moment(props.version.startDate).add(day, 'days').toDate())}</>
        }))}
        required
        disabled={disabled || idWatch > 0}
      />
    </Grid>
    <Grid item sm={1}>
      <FormInputNumber label={i18next.t('invoice-lineitem-count')} name={`extraLineItems.${extraLineItemIndex}.count`} control={control} disabled={disabled || idWatch > 0} />
    </Grid>
    <Grid item sm={1}>
      <FormInputText label={i18next.t('invoice-lineitem-sku')} name={`extraLineItems.${extraLineItemIndex}.sku`} control={control} disabled />
    </Grid>
    <Grid item sm={3}>
      <FormInputText label={i18next.t('invoice-lineitem-header')} name={`extraLineItems.${extraLineItemIndex}.header`} control={control} disabled={disabled || idWatch > 0} />
    </Grid>
    {componentsWatch.length > 1 &&
      <Grid item sm={2}>
        <Grid container>
          {componentsFields.map((c, index) => (<Grid item sm={12} key={index}>
            <InvoiceFormExtraLineItemComponent extraLineItemComponentIndex={index} {...props} disabled={disabled || idWatch > 0}/>
          </Grid>))}
        </Grid>
      </Grid>
    } 
    {componentsWatch.length <= 1 &&
      <Grid item sm={2}>
        <FormInputCurrency
          name={`extraLineItems.${extraLineItemIndex}.priceItem`}
          label={isPricesNet ? i18next.t('invoice-lineitem-price-net') : i18next.t('invoice-lineitem-price-gross')}
          currency={version.priceList.currency}
          control={control}
          disabled={disabled || idWatch > 0}
        />
      </Grid>
    }
    <Grid item sm={1}>
      <FormInputCurrency name={`extraLineItems.${extraLineItemIndex}.priceNet`} label={i18next.t('invoice-lineitem-net')} currency={version.priceList.currency} control={control} disabled />
    </Grid>
    <Grid item sm={1}>
      <FormInputCurrency name={`extraLineItems.${extraLineItemIndex}.priceGross`} label={i18next.t('invoice-lineitem-gross')} currency={version.priceList.currency} control={control} disabled />
    </Grid>
    <Grid item sm={1}>
      {mediaWatch && <IconButton
        onClick={() => {
          if (mediaWatch.base64) {
            setMediaBase64(mediaWatch.base64)
          } else {
            mediaQuery({
              variables: {
                mediaId: mediaWatch.id
              }
            }).then(res => {
              const base64 = res.data?.readWlOfferInvoiceMediaBase64
              if (base64) {
                setMediaBase64(base64)
              }
            })
          }
        }}
      >
        <AttachmentIcon />
      </IconButton>}
      {!disabled && idWatch <= 0 && <IconButton
        onClick={() => {
          extraLineItemRemove(extraLineItemIndex);
        }}
      >
        <DeleteIcon />
      </IconButton>}
    </Grid>
    {mediaWatch && <InformationDialog
      title={mediaWatch.name}
      open={!!mediaBase64}
      onConfirm={() => setMediaBase64(undefined)}
      maxWidth="md"
      fullWidth
    >
      <Box p={2}>
        <Grid container>
          <Grid item xs={12}>
            {mediaBase64 && <img width="100%" src={`data:${mediaWatch.mimeType};base64,${mediaBase64}`} />}
          </Grid>
        </Grid>
      </Box>
    </InformationDialog>}
  </>
}

function InvoiceFormExtraLineItemComponent(props: InvoiceFormExtraLineItemComponentProps) {
  const { extraLineItemIndex, version, disabled, isPricesNet, extraLineItemComponentIndex } = props
  const { control, watch } = useFormContext<InvoiceFormType>();

  const nameWatch = watch(`extraLineItems.${extraLineItemIndex}.components.${extraLineItemComponentIndex}.name`)
  const typeWatch = watch(`extraLineItems.${extraLineItemIndex}.components.${extraLineItemComponentIndex}.taxTypeId`)

  const taxRate = props.quickPriceCalculator.getTaxRateForType(typeWatch);
  return (
    <FormInputCurrency
      name={`extraLineItems.${extraLineItemIndex}.components.${extraLineItemComponentIndex}.price`}
      label={`${nameWatch}${taxRate ? ` (${formatPercentage(taxRate.rate)})` : ''}${i18next.t(`invoice-lineitem-suffix-${isPricesNet ? 'net' : 'gross'}`)}`}
      currency={version.priceList.currency}
      control={control}
      required
      disabled={disabled}
    />
  );
}

interface InvoiceFormCancellationProps extends InvoiceFormProps {
  ruleIndex: number;
  disabled: boolean;
}
interface InvoiceFormCancellationLineItemProps extends InvoiceFormCancellationProps {
  lineItemIndex: number;
}

function InvoiceFormCancellation(props: InvoiceFormCancellationProps) {
  const { ruleIndex, version, disabled } = props
  const { control, watch, getValues, setValue } = useFormContext<InvoiceFormType>();

  const phaseWatch = watch(`cancellations.${ruleIndex}.rate`);
  const daysToEventWatch = watch(`cancellations.${ruleIndex}.daysToEvent`);

  const { fields: lineItemsFields } = useFieldArray({
    control,
    name: `cancellations.${ruleIndex}.lineItems`,
  });

  return <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>
        Stornophase {formatPercentage(phaseWatch)} (bis {daysToEventWatch} Tage)
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container>
        {lineItemsFields.map((li, index) => (
          <InvoiceFormCancellationLineItem key={index} lineItemIndex={index} {...props} />
        ))}
      </Grid>
    </AccordionDetails>
  </Accordion>
}

function InvoiceFormCancellationLineItem(props: InvoiceFormCancellationLineItemProps) {
  const { ruleIndex, lineItemIndex, version } = props
  const { control, watch, getValues } = useFormContext<InvoiceFormType>();

  const dayWatch = watch(`cancellations.${ruleIndex}.lineItems.${lineItemIndex}.day`);
  const skuWatch = watch(`cancellations.${ruleIndex}.lineItems.${lineItemIndex}.sku`);
  const headerWatch = watch(`cancellations.${ruleIndex}.lineItems.${lineItemIndex}.header`);

  const dayDate = moment(version.startDate).add(dayWatch, 'days').toDate();

  return <>
    <Grid item sm={2}>
      {formatOfferDay(dayWatch!)} - {formatDate(dayDate)}
    </Grid>
    <Grid item sm={1}>
      <FormInputNumber name={`cancellations.${ruleIndex}.lineItems.${lineItemIndex}.count`} control={control} disabled />
    </Grid>
    <Grid item sm={8}>
      <Typography>
        {headerWatch} ({skuWatch})
      </Typography>
    </Grid>
    <Grid item sm={1}>
      <FormInputCurrency name={`cancellations.${ruleIndex}.lineItems.${lineItemIndex}.priceGross`} currency={version.priceList.currency} control={control} disabled />
    </Grid>
  </>
}

function InvoiceForm(props: InvoiceFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [approveInvoice] = useMutation(INVOICE_APPROVE_HOTEL_MUTATION);

  const [selectedTab, setSelectedTab] = useState(0);
  const [offer, setOffer] = useState(props.offer);
  const [version, setVersion] = useState(props.version);
  const [event, setEvent] = useState(props.event);

  const [extraUploadedFile, setExtraUploadedFile] = useState<{ name: string, mimeType: string, base64: string }>()
  const [extraSku, setExtraSku] = useState<string>('')

  const [hasAllAccepted, setHasAllAccepted] = useState(false);

  const [approvedId, setApprovedId] = useState(0);
  useEffect(() => {
    if (approvedId > 0) navigate(`/invoices/${offer.id}/${approvedId}`);
  }, [approvedId]);

  const canApprove = version.status === EOfferVersionStatus.IV_WAITFORHOTEL
  const priceList = props.quickPriceCalculator.getPriceList({ date: new Date(), priceListId: version.priceList.id });

  const toFormSchema = (offer: typeof props.offer, version: typeof props.version, event: typeof props.event): InvoiceFormType => {

    const _getLookupCode = (dayIndex: number, lineItemIndex: number): EProductLookupCode | null => {
      const sku = version.contentBlocks[dayIndex].lineItems[lineItemIndex].sku
      if (!sku) return null

      const lookupCode = props.quickPriceCalculator.allProductsDb!.find(p => p.sku === sku)?.lookupCode || props.quickPriceCalculator.allBundlesDb!.find(b => b.sku === sku)?.lookupCode
      if (!lookupCode) return null

      return lookupCode as EProductLookupCode
    }

    const _hasAttendances = (dayIndex: number, lineItemIndex: number): boolean => {
      if (!event || event.attendances.length === 0) return false

      const lookupCode = _getLookupCode(dayIndex, lineItemIndex)
      if (!lookupCode) return false

      return isValidAttendanceLookupCode(lookupCode)
    }
    const _createAttendances = (dayIndex: number, lineItemIndex: number): AttendanceFormType[] => {
      if (!_hasAttendances(dayIndex, lineItemIndex)) return []

      const lookupCode = _getLookupCode(dayIndex, lineItemIndex)!
      const lineItem = version.contentBlocks[dayIndex].lineItems[lineItemIndex]

      const attendances = event!.attendances.filter(a => a.days.findIndex(d => d.day === lineItem.day) >= 0).map(a => {
        const aDay = a.days.find(d => d.day === lineItem.day)!

        if (mapAttendanceRegistered(lookupCode as EAM_EventProductLookupCodes, aDay)) {
          return {
            profileId: a.profile.id,
            profileName: a.profile.lastname + ' ' + a.profile.firstname,
            day: lineItem.day,
            directPay: mapAttendanceDirectPay(lookupCode as EAM_EventProductLookupCodes, a, aDay),
            show: mapAttendanceShow(lookupCode as EAM_EventProductLookupCodes, aDay),
          }
        }
      })
      return attendances.filter(a => a).map(a => a!)
    }

    let initDayCounter = 0

    return {
      extraLineItems: version.contentBlocks.reduce<LineItemFormType[]>((agg, cb, cbIndex) => {
        if (cb.type === EOfferVersionContentBlockType.LINE_ITEMS && cb.lineItems.length > 0) {
          for (const li of cb.lineItems.filter(li => li.media && li.count > 0 && li.priceGross > 0)) {
            agg.push({
              lineItemId: li.id,
              day: li.day,
              count: li.count,
              sku: li.sku,
              header: li.header,
              priceItem: li.priceItem,
              priceGross: li.priceGross,
              priceNet: li.priceNet,
              components: li.components.map(c => ({
                taxTypeId: c.type.id,
                name: c.type.name,
                price: c.price,
              })),
              taxes: li.taxes.map(t => ({
                taxRateId: t.rate.id,
                name: t.type.name,
                rate: t.rate.rate,
                price: t.price,
              })),
              media: li.media ? {
                id: li.media.id,
                name: li.media!.name,
                mimeType: li.media!.mimeType
              } : null,
              hasAttendances: false,
              attendances: []
            })           
          }
        }
        return [...agg]
      }, []),
      days: version.contentBlocks.map((cb, cbIndex) => (cb.type === EOfferVersionContentBlockType.LINE_ITEMS && cb.lineItems.length > 0) ? ({
        contentBlockId: cb.id,
        day: cb.lineItems[0].day,
        accepted: false,
        expanded: 0 === initDayCounter++,
        lineItems: cb.lineItems.map((li, liIndex) => !li.media ? ({
          lineItemId: li.id,
          day: li.day,
          count: li.count,
          sku: li.sku,
          header: li.header,
          priceItem: li.priceItem,
          priceGross: li.priceGross,
          priceNet: li.priceNet,
          components: li.components.map(c => ({
            taxTypeId: c.type.id,
            name: c.type.name,
            price: c.price,
          })),
          taxes: li.taxes.map(t => ({
            taxRateId: t.rate.id,
            name: t.type.name,
            rate: t.rate.rate,
            price: t.price,
          })),
          media: null,
          hasAttendances: _hasAttendances(cbIndex, liIndex),
          attendances: _createAttendances(cbIndex, liIndex)
        }) : null).filter(li => li && (li.hasAttendances || (li.count > 0 && li.priceGross > 0))).map(li => li!),
      }) : null).filter(cb => cb).map(cb => cb!),
      cancellations: version.contentBlocks.map((cb, cbIndex) => (cb.type === EOfferVersionContentBlockType.CANCELLATION_ITEMS && cb.lineItems.length > 0) ? ({
        contentBlockId: cb.id,
        daysToEvent: cb.cancellationRule!.daysToEvent,
        rate: cb.cancellationRule!.rate,
        lineItems: cb.lineItems.map((li, liIndex) => !li.media && li.count > 0 && li.priceGross > 0 ? ({
          lineItemId: li.id,
          day: li.day,
          count: li.count,
          sku: li.sku,
          header: li.header,
          priceItem: li.priceItem,
          priceGross: li.priceGross,
          priceNet: li.priceNet,
          components: li.components.map(c => ({
            taxTypeId: c.type.id,
            name: c.type.name,
            price: c.price,
          })),
          taxes: li.taxes.map(t => ({
            taxRateId: t.rate.id,
            name: t.type.name,
            rate: t.rate.rate,
            price: t.price,
          })),
          media: null,
          hasAttendances: false,
          attendances: []
        }) : null).filter(li => li).map(li => li!),
      }) : null).filter(cb => cb).map(cb => cb!),
      agreedCancellationWaiveNet: version.agreedCancellationWaiveNet,
      manualCancellationWaiveNet: version.manualCancellationWaiveNet,
      totalPriceNet: version.totalPriceNet,
      totalPriceGross: version.totalPriceGross,
      totalTaxes: version.taxes.map(t => ({
        taxRateId: t.rate.id,
        name: t.type.name,
        rate: t.rate.rate,
        price: t.price,
      })),
    }
  }

  const defaultValues = toFormSchema(props.offer, props.version, props.event)
  const form = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema) as any,
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    control,
    trigger,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors: validationErrors, isValid, isDirty, isValidating, isSubmitting },
  } = form;

  const { fields: daysFields } = useFieldArray({
    control,
    name: 'days',
  });

  const { fields: extraLineItemsFields, append: extraLineItemAppend, remove: extraLineItemRemove } = useFieldArray({
    control,
    name: 'extraLineItems',
  });

  const { fields: cancellationsFields } = useFieldArray({
    control,
    name: 'cancellations',
  });

  const manualCancellationWaiveNetWatch = watch('manualCancellationWaiveNet')
  useEffect(() => {
    onTotalsChanged()
  }, [manualCancellationWaiveNetWatch])

  const lineItemChanged = (dayIndex: number, lineItemIndex: number) => {
    const lineItem = getValues(`days.${dayIndex}.lineItems.${lineItemIndex}`);
    const lineItemDate = moment(version.startDate).add(lineItem.day, 'days').toDate();

    try {
      const calcLineItem = props.quickPriceCalculator.getSKULineItem(lineItem.sku!, lineItemDate, lineItem.count);
      if (!calcLineItem) return

      setValue(`days.${dayIndex}.lineItems.${lineItemIndex}.priceItem`, calcLineItem.priceItem);
      setValue(`days.${dayIndex}.lineItems.${lineItemIndex}.priceGross`, calcLineItem.priceGross);
      setValue(`days.${dayIndex}.lineItems.${lineItemIndex}.priceNet`, calcLineItem.priceNet);
      setValue(
        `days.${dayIndex}.lineItems.${lineItemIndex}.components`,
        calcLineItem.components.map(c => ({
          taxTypeId: c.type.id,
          name: c.type.name,
          price: c.price,
        })),
      );
      setValue(
        `days.${dayIndex}.lineItems.${lineItemIndex}.taxes`,
        calcLineItem.taxes.map(t => ({
          taxRateId: t.rate.id,
          name: t.type.name,
          rate: t.rate.rate,
          price: t.price,
        })),
      );
      setTimeout(() => onTotalsChanged());
    } catch (err) {
      dispatchException(dispatch, err);
    }
  }
  const lineItemAttendanceChanged = (dayIndex: number, lineItemIndex: number, attendanceIndex: number, show: boolean) => {
    const attendanceItems = getValues(`days.${dayIndex}.lineItems.${lineItemIndex}.attendances`);
    const itemCount = attendanceItems.filter(a => !a.directPay || (a.directPay && !a.show)).length;
    const lineItemCount = getValues(`days.${dayIndex}.lineItems.${lineItemIndex}.count`);
    if (lineItemCount !== itemCount) {
      console.log('lineItemAttendanceChanged setValue', lineItemCount, `days.${dayIndex}.lineItems.${lineItemIndex}.count`, itemCount)
      setValue(`days.${dayIndex}.lineItems.${lineItemIndex}.count`, itemCount);
      setTimeout(() => lineItemChanged(dayIndex, lineItemIndex), 0);
    }
  }

  const extraLineItemAdd = (sku: string, media: MediaFormType) => {
    try {
      const header = props.productTexts.getProductText({
        sku: sku,
        hotelId: offer.hotel.id,
        position: EContentProductDetailPosition.HEADER,
        language: offer.language
      });

      const calcLineItem = props.quickPriceCalculator.getSKULineItem(sku, version.startDate, 1);
      if (calcLineItem) {
        extraLineItemAppend({
          lineItemId: 0,
          sku: calcLineItem.sku,
          count: calcLineItem.count,
          priceItem: calcLineItem.priceItem,
          priceGross: calcLineItem.priceGross,
          priceNet: calcLineItem.priceNet,
          day: 0,
          header: header || calcLineItem.name,
          media: media,
          hasAttendances: false,
          attendances: [],
          components: calcLineItem.components.map(c => ({
            taxTypeId: c.type.id,
            name: c.type.name,
            price: c.price,
          })),
          taxes: calcLineItem.taxes.map(t => ({
            taxRateId: t.rate.id,
            name: t.type.name,
            rate: t.rate.rate,
            price: t.price,
          })),
        });
        setTimeout(() => onTotalsChanged(), 0);
      }
    } catch (err) {
      dispatchException(dispatch, err);
    }
  }
  const extraLineItemChanged = (eliIndex: number) => {
    const lineItem = getValues(`extraLineItems.${eliIndex}`);
    const lineItemDate = moment(version.startDate).add(lineItem.day, 'days').toDate();

    try {
      const priceItem = lineItem.components.length > 1 ? lineItem.components.reduce((s, c) => s + c.price, 0) : lineItem.priceItem;
      
      const calcLineItem = props.quickPriceCalculator.getSKULineItem(lineItem.sku!, lineItemDate, lineItem.count, {
        price: priceItem,
        bundlePriceFromProduct: false,
        components: lineItem.components.map(c => ({
          taxTypeId: c.taxTypeId,
          price: c.price,
        })),
      });
      if (!calcLineItem) return

      if (lineItem.components.length > 1) {
        setValue(`extraLineItems.${eliIndex}.priceItem`, priceItem);
      }
      setValue(`extraLineItems.${eliIndex}.priceGross`, calcLineItem.priceGross);
      setValue(`extraLineItems.${eliIndex}.priceNet`, calcLineItem.priceNet);
      setValue(
        `extraLineItems.${eliIndex}.components`,
        calcLineItem.components.map(c => ({
          taxTypeId: c.type.id,
          name: c.type.name,
          price: c.price,
        })),
      );
      setValue(
        `extraLineItems.${eliIndex}.taxes`,
        calcLineItem.taxes.map(t => ({
          taxRateId: t.rate.id,
          name: t.type.name,
          rate: t.rate.rate,
          price: t.price,
        })),
      );
      setTimeout(() => onTotalsChanged(), 0);
    } catch (err) {
      dispatchException(dispatch, err);
    }
  }
  const onTotalsChanged = () => {
    const { totalPriceNet, totalPriceGross, totalTaxes } = calculateTotals({
      agreedCancellationWaiveNet: getValues('agreedCancellationWaiveNet'),
      manualCancellationWaiveNet: getValues('manualCancellationWaiveNet'),
      contentBlocks: [
        ...getValues('days').map(d => ({ type: EOfferVersionContentBlockType.LINE_ITEMS, lineItems: d.lineItems })),
        {
          type: EOfferVersionContentBlockType.LINE_ITEMS,
          lineItems: getValues('extraLineItems')
        },
        ...getValues('cancellations').map(d => ({ type: EOfferVersionContentBlockType.CANCELLATION_ITEMS, lineItems: d.lineItems })),
      ]
    })
    setValue('totalPriceNet', totalPriceNet);
    setValue('totalPriceGross', totalPriceGross);
    setValue('totalTaxes', _.orderBy(totalTaxes, [t => t.name], 'asc'));
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = async (values: InvoiceFormType) => {
    if (!canApprove) return

    const attendances: WLInvoiceAttendanceInput[] = []
    const _get_a = (day: number, profileId: number) => {
      let r = attendances.find(a => a.day === day && a.profileId === profileId)
      if (!r) {
        r = {
          day: day,
          profileId: profileId
        }
        attendances.push(r)
      }
      return r
    }

    for (const day of values.days) {
      for (const li of day.lineItems.filter(li => li.hasAttendances)) {
        if (!li.sku) continue

        const lookupCode = props.quickPriceCalculator.allProductsDb!.find(p => p.sku === li.sku)?.lookupCode || props.quickPriceCalculator.allBundlesDb!.find(b => b.sku === li.sku)?.lookupCode
        if (!lookupCode) continue
        
        mapShowAttendance(lookupCode as EAM_EventProductLookupCodes, li.attendances.map(a => a.show), li.attendances.map(a => _get_a(day.day, a.profileId)))
      }
    }
    try {
      const res = await approveInvoice({
        variables: {
          offerVersionId: props.version.id,
          attendances: attendances,
          extraLineItems: values.extraLineItems.filter(eli => eli.lineItemId <= 0).map(eli => ({
            day: eli.day,
            sku: eli.sku,
            header: eli.header,
            details: '',
            count: eli.count,
            sequence: 0,
            priceItem: eli.priceItem,
            priceNet: eli.priceNet,
            priceGross: eli.priceGross,
            components: eli.components.map(elic => ({
              taxTypeId: elic.taxTypeId,
              price: elic.price
            })),
            taxes: eli.taxes.map(elit => ({
              taxRateId: elit.taxRateId,
              price: elit.price
            })),
            media: eli.media ? {
              name: eli.media.name,
              mimeType: eli.media.mimeType,
              base64: eli.media.base64!
            } : undefined
          }))
        },
        update: cache => {
          EVICT_INVOICE_QUERIES(cache);
        },
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_INVOICE_QUERIES(props.offer.id, props.version.id),
      });
      reset(toFormSchema(offer, res.data!.approveInvoiceHotel, event));
      setApprovedId(res.data!.approveInvoiceHotel.id);
      dispatchMessage(dispatch, i18next.t('invoice-status-approved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle([i18next.t(`invoices-list-page-title-${props.offer.source}`), props.offer])}
        </title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {props.offer.refCode} - {i18next.t(`enums-EOfferVersionStatus-${props.version.status}`)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {canApprove && 
            <ConfirmationButton
              sx={{ marginRight: 2 }}
              variant="contained"
              confirmationQuestion={i18next.t('invoices-confirm-question')}
              confirmationTitle={i18next.t('invoices-confirm-question-title')}
              startIcon={<ThumbUpIcon />}
              disabled={!isValid || !hasAllAccepted || isSubmitting}
              onConfirm={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t('invoices-confirm-title')}
            </ConfirmationButton>
          }
          <Button
            sx={{ marginRight: 2 }}
            variant="contained"
            color="secondary"
            startIcon={<DownloadIcon />}
            onClick={() => {
              window.open(getFullBackendUrl(`/backend/pdf/downloadOffer/${props.version.id}`), '_blank');
            }}
          >
            {i18next.t('invoices-download-proforma-invoice')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <UnsavedChangesPrompt isDirty={isDirty} />
          <FormProvider {...form}>
            <CustomTabsControlled
              selected={selectedTab}
              handleChange={selected => setSelectedTab(selected)}
              headers={[
                i18next.t('invoice-lineitems-tab'),
                i18next.t('invoice-extras-tab'),
                i18next.t('invoice-cancellation-tab'),
                i18next.t('invoice-info-tab'),
              ]}
              icons={[
                canApprove && (!hasAllAccepted || Object.keys(validationErrors).findIndex(v => v.startsWith('days')) >= 0) ? <ErrorIcon /> : undefined,
                canApprove && Object.keys(validationErrors).findIndex(v => v.startsWith('extraLineItems')) >= 0 ? <ErrorIcon /> : undefined,
                canApprove && Object.keys(validationErrors).findIndex(v => v.startsWith('cancellations')) >= 0 ? <ErrorIcon /> : undefined,
                undefined
              ]}
              tabs={[
                <Grid container spacing={2}>
                  {daysFields.map((day, index) => (
                    <Grid item xs={12} key={index}>
                      <InvoiceFormDay form={defaultValues}
                        dayIndex={index}
                        disabled={!canApprove || isSubmitting}
                        setHasAllAccepted={setHasAllAccepted}
                        lineItemAttendanceChanged={lineItemAttendanceChanged}
                        {...props}
                      />
                    </Grid>
                  ))}
                </Grid>,
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {extraLineItemsFields.map((li, index) => (
                        <InvoiceFormExtraLineItem form={defaultValues}
                          key={index}
                          extraLineItemIndex={index}
                          disabled={!canApprove || isSubmitting}
                          isPricesNet={priceList.isPricesNet}
                          extraLineItemRemove={(index) => {
                            extraLineItemRemove(index);
                            onTotalsChanged();
                          }}
                          {...props}
                        />
                      ))}
                    </Grid>
                  </Grid>
                  {canApprove && extraLineItemsFields.map((li, index) => <InvoiceFormExtraLineItemListener key={`L_${index}`} extraLineItemIndex={index} extraLineItemChanged={extraLineItemChanged} />)}
                  {canApprove && !isSubmitting && <Grid item xs={12}>
                    <FileUploadButton
                      name="uploadMedia"
                      startIcon={<UploadIcon />}
                      accept={'image/*'}
                      binary={(name, type) => true}
                      onChange={async (name, mimeType, base64) => {
                        setExtraUploadedFile({ name, mimeType, base64 })
                      }}
                    >
                      {extraUploadedFile ? extraUploadedFile.name : i18next.t('invoice-extras-upload')}
                    </FileUploadButton>
                    <Select
                      value={extraSku}
                      displayEmpty
                      onChange={event => {
                        if (!event.target.value) return;
                        setExtraSku(`${event.target.value}`);
                      }}
                    >
                      <MenuItem value={''}>
                        <em>{i18next.t('invoice-extras-selectsku')}</em>
                      </MenuItem>
                      {props.quickPriceCalculator.allProductsDb && props.quickPriceCalculator.allProductsDb.map((p, index) => (
                        <MenuItem key={`P_${index}`} value={p.sku}>
                          {p.sku} {p.name}
                        </MenuItem>
                      ))}
                      <ListSubheader><Divider /></ListSubheader>
                      {props.quickPriceCalculator.allBundlesDb && props.quickPriceCalculator.allBundlesDb.map((b, index) => (
                        <MenuItem key={`B_${index}`} value={b.sku}>
                          {b.sku} {b.name}
                        </MenuItem>
                      ))}
                      <ListSubheader><Divider /></ListSubheader>
                      {props.quickPriceCalculator.allFacilitiesDb && props.quickPriceCalculator.allFacilitiesDb.map((f, index) => (
                        <MenuItem key={`F_${index}`} value={f.sku}>
                          {f.sku} {f.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Button
                      sx={{ marginRight: 2 }}
                      variant="contained"
                      color="secondary"
                      startIcon={<AddIcon />}
                      disabled={!extraUploadedFile || !extraSku}
                      onClick={() => {
                        extraLineItemAdd(extraSku, { id: 0, ...extraUploadedFile! });
                        setExtraUploadedFile(undefined);
                        setExtraSku('');
                      }}
                    >
                      {i18next.t('invoice-extras-add')}
                    </Button>
                  </Grid>}
                </Grid>,
                <Grid container spacing={2}>
                  {cancellationsFields.map((day, index) => (
                    <Grid item xs={12} key={index}>
                      <InvoiceFormCancellation key={index}
                        ruleIndex={index}
                        disabled={!canApprove || isSubmitting}
                        {...props}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <FormInputCurrency name={'agreedCancellationWaiveNet'} label={i18next.t('invoice-cancellation-agreedwaive')} currency={version.priceList.currency} control={control} disabled />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputCurrency name={'manualCancellationWaiveNet'} label={i18next.t('invoice-cancellation-manualwaive')} currency={version.priceList.currency} control={control} disabled={!canApprove || isSubmitting} />
                  </Grid>
                </Grid>,
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    INFO
                  </Grid>
              </Grid>
              ]}
            />
          </FormProvider>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            {i18next.t('invoice-totals')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {i18next.t('invoice-totals-net')}: {formatPrice(getValues('totalPriceNet'), version.priceList.currency)}
        </Grid>
        <Grid item xs={12}>
          {i18next.t('invoice-totals-taxes')}: {formatPrice(getValues('totalPriceGross') - getValues('totalPriceNet'), version.priceList.currency)}
        </Grid>
        <Grid item xs={12}>
          {i18next.t('invoice-totals-gross')}: {formatPrice(getValues('totalPriceGross'), version.priceList.currency)}
        </Grid>
      </Grid>
    </>
  );
}

export default function Invoice(props: InvoiceProps) {
  const offerQuery = useQuery(INVOICE_VIEW_QUERY, {
    variables: { id: props.id }
  });

  const loading = offerQuery.loading;
  const error = offerQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else if (!offerQuery.data?.viewWlOffer || !offerQuery.data?.viewWlOfferInvoiceVersion) return <Navigate to={`/offers`} />

  const calc = new QuickPriceCalculator();
  calc.hydrate(offerQuery.data!.invoiceQuickPriceDehydrate!);

  const pt = new ProductTextSelector();
  pt.hydrate(offerQuery.data!.invoiceProductTextDehydrate!);

  return (
    <InvoiceForm offer={offerQuery.data!.viewWlOffer!} version={offerQuery.data!.viewWlOfferInvoiceVersion!} event={offerQuery.data!.viewWlOfferInvoiceEvent} quickPriceCalculator={calc} productTexts={pt}/>
  );
}
