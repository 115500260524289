import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';

import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from '@mui/material';
import { Button, Grid, Tab, Tabs, MenuItem, Select } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormInputCurrency } from 'components/form/FormInputCurrency';
import { FormLabel } from 'components/form/FormLabel';
import SimpleAccordion from 'components/SimpleAccordion';
import { hintValidationAlert } from 'helper/form';
import {
  formatOfferDay,
  OfferTemplateInput,
  OfferTemplateContentBlock,
  OfferTemplateLineItem,
  OfferTemplateFormField,
} from '../../semshared/offer/offer';
import { QuickPriceCalculatorContext } from './context';
import { FormInputDropdown } from 'components/form/FormInputDropdown';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { Editor } from '@tinymce/tinymce-react';
import { FormInputRichText } from 'components/form/FormInputRichTextEditor';

interface IEditorAccordionProps<LineItemType> {
  cssStyles: string;
  contentBlock: OfferTemplateContentBlock;
  index: number;
  showHtmlTab: boolean;
  control: any;
  disabled?: boolean;
  onLineItemChanged: (index: number, lineItemIndex: number) => void;
  onLineItemPriceComponentChanged: (index: number, lineItemIndex: number, componentIndex: number) => void;
  onLineItemSKUAdded: (cbIndex: number, sku: string) => LineItemType | null | undefined;
  onLineItemFreeAdded: (cbIndex: number, taxTypeId: number) => LineItemType | null | undefined;
  onTotalsChanged: () => void;
  onDelete: (index: number) => void;
}

interface IEditorAccordionLineItemProps {
  contentBlock: OfferTemplateContentBlock;
  contentBlockIndex: number;
  lineItemIndex: number;
  control: any;
  disabled?: boolean;
  onLineItemChanged: (index: number, lineItemIndex: number) => void;
  onLineItemPriceComponentChanged: (index: number, lineItemIndex: number, componentIndex: number) => void;
}

interface IEditorAccordionFormFieldProps {
  cssStyles: string;
  contentBlock: OfferTemplateContentBlock;
  contentBlockIndex: number;
  formFieldIndex: number;
  control: any;
  disabled?: boolean;
}

interface IEditorAccordionLineItemComponentProps {
  contentBlock: OfferTemplateContentBlock;
  contentBlockIndex: number;
  lineItemIndex: number;
  componentIndex: number;
  control: any;
  disabled?: boolean;
  onLineItemChanged: (index: number, lineItemIndex: number) => void;
  onLineItemPriceComponentChanged: (index: number, lineItemIndex: number, componentIndex: number) => void;
}

function EditorAccordionLineItem({
  contentBlock,
  contentBlockIndex,
  lineItemIndex,
  control,
  disabled,
  onLineItemChanged,
  onLineItemPriceComponentChanged,
}: IEditorAccordionLineItemProps) {
  const { watch, getValues } = useFormContext<OfferTemplateInput>();

  const { fields: componentsFields } = useFieldArray({
    control,
    name: `contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components`,
  });

  const priceItemWatch = watch(
    `contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.priceItem`,
    _.get(contentBlock, `lineItems[${lineItemIndex}].priceItem`),
  );
  const countWatch = watch(
    `contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.count`,
    _.get(contentBlock, `lineItems[${lineItemIndex}].count`),
  );

  const quickPriceCalculator = useContext(QuickPriceCalculatorContext);
  const lineItemDay = getValues(`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.day`);
  const lineItemDate = moment(getValues('startDate')).add(lineItemDay, 'days').toDate();

  let isPricesNet = false;
  try {
    const priceList = quickPriceCalculator?.getPriceList({
      date: lineItemDate,
    });
    if (priceList) {
      isPricesNet = priceList.isPricesNet;
    }
  } catch (err) {}

  useEffect(() => {
    onLineItemChanged(contentBlockIndex, lineItemIndex);
  }, [priceItemWatch, countWatch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={10}>
        <FormInputText
          name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.header`}
          label={i18next.t('offerblock-lineitem-header')}
          control={control}
          required
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FormLabel
          name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.sku`}
          control={control}
          render={sku => <>{sku && <Link to={`/products/sku/${sku}`}>{sku}</Link>}</>}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.details`}
          label={i18next.t('offerblock-lineitem-details')}
          control={control}
          textFieldProps={{
            multiline: true,
            rows: 3,
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={3}>
        <FormInputNumber
          name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.count`}
          label={i18next.t('offerblock-lineitem-count')}
          control={control}
          required
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={3}>
        <FormInputCurrency
          name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.priceItem`}
          label={isPricesNet ? i18next.t('offerblock-lineitem-priceitem-net') : i18next.t('offerblock-lineitem-priceitem-gross')}
          currency="EUR"
          control={control}
          disabled={disabled || watch(`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components`).length > 1}
          required
        />
      </Grid>
      <Grid item xs={3}>
        <FormInputCurrency
          name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.priceNet`}
          label={i18next.t('offerblock-lineitem-pricenet')}
          currency="EUR"
          control={control}
          disabled
        />
      </Grid>
      <Grid item xs={3}>
        <FormInputCurrency
          name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.priceGross`}
          label={i18next.t('offerblock-lineitem-pricegross')}
          currency="EUR"
          control={control}
          disabled
        />
      </Grid>
      {watch(`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components`).length > 1 && (
        <Grid item xs={12}>
          <Paper>
            <Grid container padding={2}>
              <Grid item xs={12} paddingBottom={3}>
                <Typography>{i18next.t('offerblock-lineitem-components')}</Typography>
              </Grid>
              {componentsFields.map((c, ci) => (
                <React.Fragment key={ci}>
                  <Grid item xs={6} padding={1}>
                    <EditorAccordionLineItemComponent
                      contentBlock={contentBlock}
                      contentBlockIndex={contentBlockIndex}
                      lineItemIndex={lineItemIndex}
                      componentIndex={ci}
                      control={control}
                      disabled={disabled}
                      onLineItemChanged={onLineItemChanged}
                      onLineItemPriceComponentChanged={onLineItemPriceComponentChanged}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}

function EditorAccordionFormField({ cssStyles, contentBlock, contentBlockIndex, formFieldIndex, control, disabled }: IEditorAccordionFormFieldProps) {
  const { watch, setValue, getValues } = useFormContext<OfferTemplateInput>();
  const type = watch(
    `contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.type`,
    _.get(contentBlock, `formFields[${formFieldIndex}].type`),
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {type === 'TEXTAREA_RICHTEXT' && (
          <FormInputRichText
            options={{
              content_style: cssStyles,
            }}
            placeholder={watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.placeholder`) || ''}
            name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.value`}
            label={watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.label`)}
            control={control}
            required={watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.required`) || false}
            disabled={disabled}
          />
        )}
        {type === 'TEXTAREA_PLAINTEXT' && (
          <FormInputText
            name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.defaultValue`}
            label={i18next.t('offerblock-formfield-defaultvalue')}
            control={control}
            required
            disabled={disabled}
          />
        )}
      </Grid>
    </Grid>
  );
}

function EditorAccordionFormFieldDummy({
  cssStyles,
  contentBlock,
  contentBlockIndex,
  formFieldIndex,
  control,
  disabled,
}: IEditorAccordionFormFieldProps) {
  const { watch, setValue, getValues } = useFormContext<OfferTemplateInput>();
  const type = watch(
    `contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.type`,
    _.get(contentBlock, `formFields[${formFieldIndex}].type`),
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormInputDropdown
          control={control}
          name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.type`}
          label={i18next.t('offerblock-formfield-type')}
          options={[
            {
              value: 'TEXT',
              label: i18next.t('offerblock-formfield-type-text'),
            },
            {
              value: 'TEXTAREA_PLAINTEXT',
              label: i18next.t('offerblock-formfield-type-textarea-plaintext'),
            },
            {
              value: 'TEXTAREA_RICHTEXT',
              label: i18next.t('offerblock-formfield-type-textarea-richtext'),
            },
          ]}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        {i18next.t('offerblock-formfield-usage')}
        {watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.type`) === 'TEXTAREA_RICHTEXT' && (
          <em>{`{{{contentBlock.formFields.${watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.name`) || ''}}}}`}</em>
        )}
        {watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.type`) !== 'TEXTAREA_RICHTEXT' && (
          <em>{`{{contentBlock.formFields.${watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.name`) || ''}}}`}</em>
        )}
      </Grid>
      <Grid item xs={6}>
        <FormInputText
          name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.name`}
          label={i18next.t('offerblock-formfield-name')}
          control={control}
          required
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInputText
          name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.label`}
          label={i18next.t('offerblock-formfield-label')}
          control={control}
          required
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        {type === 'TEXTAREA_RICHTEXT' && (
          <FormInputRichText
            options={{
              content_style: cssStyles,
            }}
            name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.defaultValue`}
            label={i18next.t('offerblock-formfield-defaultvalue')}
            control={control}
            required
            disabled={disabled}
          />
        )}
        {type === 'TEXTAREA_PLAINTEXT' && (
          <FormInputText
            name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.defaultValue`}
            label={i18next.t('offerblock-formfield-defaultvalue')}
            control={control}
            textFieldProps={{
              multiline: true,
              rows: 3,
            }}
            required
            disabled={disabled}
          />
        )}
        {type === 'TEXT' && (
          <FormInputText
            name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.defaultValue`}
            label={i18next.t('offerblock-formfield-defaultvalue')}
            control={control}
            required
            disabled={disabled}
          />
        )}
      </Grid>
      {false && (
        <Grid item xs={12}>
          <SimpleAccordion header="Erweiterte Formularfeldeinstellungen">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormInputCheckbox
                  name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.required`}
                  label={i18next.t('offerblock-formfield-required')}
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputText
                  name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.placeholder`}
                  label={i18next.t('offerblock-formfield-placeholder')}
                  control={control}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputText
                  name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.helpText`}
                  label={i18next.t('offerblock-formfield-helptext')}
                  control={control}
                  required
                />
              </Grid>
            </Grid>
          </SimpleAccordion>
        </Grid>
      )}
    </Grid>
  );
}

function EditorAccordionLineItemComponent({
  contentBlock,
  contentBlockIndex,
  lineItemIndex,
  componentIndex,
  control,
  disabled,
  onLineItemChanged,
  onLineItemPriceComponentChanged,
}: IEditorAccordionLineItemComponentProps) {
  const { watch } = useFormContext<OfferTemplateInput>();
  const priceWatch = watch(
    `contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components.${componentIndex}.price`,
    _.get(contentBlock, `lineItems[${lineItemIndex}].components[${componentIndex}].price`),
  );

  useEffect(() => {
    onLineItemPriceComponentChanged(contentBlockIndex, lineItemIndex, componentIndex);
    onLineItemChanged(contentBlockIndex, lineItemIndex);
  }, [priceWatch]);

  return (
    <FormInputCurrency
      key={`${watch(`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components.${componentIndex}.name`)}`}
      name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components.${componentIndex}.price`}
      label={watch(`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components.${componentIndex}.name`)}
      currency="EUR"
      control={control}
      required
      disabled={disabled}
    />
  );
}

export function EditorAccordion<LineItemType>({
  cssStyles,
  contentBlock,
  index,
  control,
  disabled,
  showHtmlTab,
  onDelete,
  onLineItemFreeAdded,
  onLineItemSKUAdded,
  onLineItemChanged,
  onLineItemPriceComponentChanged,
  onTotalsChanged,
}: IEditorAccordionProps<LineItemType>) {
  const [tabValue, setTabValue] = React.useState<string | null>('data');

  const quickPriceCalculator = useContext(QuickPriceCalculatorContext);
  const {
    watch,
    formState: { errors: validationErrors },
  } = useFormContext<OfferTemplateInput>();

  const {
    fields: lineItemsFields,
    append: lineItemsAppend,
    remove: lineItemsRemove,
    replace: lineItemsReplace,
  } = useFieldArray({
    control,
    name: `contentBlocks.${index}.lineItems`,
  });

  const _reassignSequenceLineItems = (lineItems: OfferTemplateLineItem[]) => {
    lineItems.forEach((li, index) => {
      li.sequence = index + 1;
    });
    lineItemsReplace(lineItems);
  };

  const {
    fields: formFieldsFields,
    append: formFieldsAppend,
    remove: formFieldsRemove,
    replace: formFieldsReplace,
  } = useFieldArray({
    control,
    name: `contentBlocks.${index}.formFields`,
  });

  const _reassignSequenceFormFields = (formFields: OfferTemplateFormField[]) => {
    formFields.forEach((li, index) => {
      li.sequence = index + 1;
    });
    formFieldsReplace(formFields);
  };

  return (
    <div>
      <Accordion defaultExpanded={contentBlock.type === 'LINE_ITEMS'}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          {hintValidationAlert(_.get(validationErrors, `contentBlocks[${index}]`))}
          <Typography>
            <FormLabel
              name={`contentBlocks.${index}`}
              control={control}
              render={(contentBlock: OfferTemplateContentBlock) => (
                <>
                  {contentBlock.type === 'CUSTOM_FORM' && (
                    <>
                      <span>
                        {i18next.t('offerblock-customform-header')} #{contentBlock.sequence}
                      </span>
                      <br />
                      <span>
                        <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                      </span>
                    </>
                  )}
                  {contentBlock.type === 'PLAIN_HTML' && (
                    <>
                      <span>
                        {i18next.t('offerblock-plainhtml-header')} #{contentBlock.sequence}
                      </span>
                      <br />
                      <span>
                        <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                      </span>
                    </>
                  )}
                  {contentBlock.type === 'LINE_ITEMS' && (
                    <>
                      <span>
                        {i18next.t('offerblock-lineitems-header-day')} {formatOfferDay((contentBlock as any).day)}
                      </span>
                      <br />
                      <span>
                        <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                      </span>
                    </>
                  )}
                  {contentBlock.type === 'CANCELLATION_ITEMS' && (
                    <>
                      <span>
                        {i18next.t('offerblock-cancellationitems-header-day')} {formatOfferDay((contentBlock as any).day)}
                      </span>
                      <br />
                      <span>
                        <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                      </span>
                    </>
                  )}
                  {contentBlock.type === 'INPUT_DAYS' && (
                    <>
                      <span>
                        {i18next.t('offerblock-inputdays-header-day')} {formatOfferDay((contentBlock as any).day)}
                      </span>
                      <br />
                      <span>
                        <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                      </span>
                    </>
                  )}
                </>
              )}
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {contentBlock.type === 'CUSTOM_FORM' && (
                <>
                  {showHtmlTab && (
                    <Tabs value={tabValue}>
                      <Tab value="data" label={i18next.t('offerblock-customform-data-tab')} onClick={() => setTabValue('data')} />
                      <Tab value="template" label={i18next.t('offerblock-customform-template-tab')} onClick={() => setTabValue('template')} />
                    </Tabs>
                  )}
                  {tabValue === 'template' && showHtmlTab && (
                    <Grid container>
                      <Grid item xs={12}>
                        <FormInputText
                          name={`contentBlocks.${index}.template`}
                          label={i18next.t('offerblock-customform-html')}
                          required
                          textFieldProps={{
                            multiline: true,
                            rows: 10,
                          }}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {tabValue === 'data' && (
                    <Grid container>
                      <Grid item xs={12}>
                        <DragDropContext
                          onDragEnd={result => {
                            if (result.destination && contentBlock.lineItems) {
                              const formFields = watch(`contentBlocks.${index}.formFields`);
                              if (!formFields) return;
                              const [reorderedItem] = formFields.splice(result.source.index, 1);
                              formFields.splice(result.destination.index, 0, reorderedItem);
                              _reassignSequenceFormFields(formFields);
                            }
                          }}
                        >
                          <Droppable droppableId={`characters_${index}`}>
                            {(provided: any) => (
                              <Grid container spacing={3} {...provided.droppableProps} ref={provided.innerRef} className={`characters_${index}`}>
                                {formFieldsFields.map((li, formFieldIndex) => (
                                  <Draggable key={`${li.id}_s2`} draggableId={`${index}_${formFieldIndex}_s2`} index={formFieldIndex} isDragDisabled={disabled}>
                                    {(provided: any) => (
                                      <Grid item xs={12} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <Accordion>
                                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                            <Typography>
                                              {watch(`contentBlocks.${index}.formFields.${formFieldIndex}.label`)} -{' '}
                                              {watch(`contentBlocks.${index}.formFields.${formFieldIndex}.name`)}
                                              <br />
                                              <span>
                                                <em>
                                                  {i18next.t(
                                                    `enums-EOfferVersionContentBlockFormFieldType-${watch(
                                                      `contentBlocks.${index}.formFields.${formFieldIndex}.type`,
                                                    )}`,
                                                  )}
                                                </em>
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <EditorAccordionFormField
                                              cssStyles={cssStyles}
                                              contentBlock={contentBlock}
                                              contentBlockIndex={index}
                                              formFieldIndex={formFieldIndex}
                                              control={control}
                                              disabled={disabled}
                                            />
                                          </AccordionDetails>
                                        </Accordion>
                                      </Grid>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </Grid>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {contentBlock.type === 'PLAIN_HTML' && showHtmlTab && (
                <FormInputRichText
                  options={{
                    content_style: cssStyles,
                  }}
                  name={`contentBlocks.${index}.template`}
                  label={i18next.t('offerblock-plainhtml-html')}
                  control={control}
                  required={true}
                  disabled={disabled}
                />
              )}
              {contentBlock.type === 'INPUT_DAYS' && showHtmlTab && (
                <FormInputText
                  name={`contentBlocks.${index}.template`}
                  label={i18next.t('offerblock-inputdays-html')}
                  required
                  textFieldProps={{
                    multiline: true,
                    rows: 10,
                  }}
                  control={control}
                  disabled={disabled}
                />
              )}
              {(contentBlock.type === 'LINE_ITEMS' || contentBlock.type === 'CANCELLATION_ITEMS') && (
                <>
                  {showHtmlTab && (
                    <Tabs value={tabValue}>
                      <Tab value="data" label={i18next.t('offerblock-lineitems-data-tab')} onClick={() => setTabValue('data')} />
                      <Tab value="template" label={i18next.t('offerblock-lineitems-template-tab')} onClick={() => setTabValue('template')} />
                    </Tabs>
                  )}
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {tabValue === 'template' && showHtmlTab && (
                        <>
                          <FormInputText
                            name={`contentBlocks.${index}.template`}
                            label={i18next.t('offerblock-lineitems-html')}
                            required
                            textFieldProps={{
                              multiline: true,
                              rows: 10,
                            }}
                            control={control}
                            disabled={disabled}
                          />
                        </>
                      )}
                      {tabValue === 'data' && (
                        <Grid container>
                          <Grid item xs={12}>
                            <DragDropContext
                              onDragEnd={result => {
                                if (result.destination && contentBlock.lineItems) {
                                  const lineItems = watch(`contentBlocks.${index}.lineItems`);
                                  if (!lineItems) return;
                                  const [reorderedItem] = lineItems.splice(result.source.index, 1);
                                  lineItems.splice(result.destination.index, 0, reorderedItem);
                                  _reassignSequenceLineItems(lineItems);
                                }
                              }}
                            >
                              <Droppable droppableId={`characters_${index}`}>
                                {(provided: any) => (
                                  <Grid container spacing={3} {...provided.droppableProps} ref={provided.innerRef} className={`characters_${index}`}>
                                    {lineItemsFields.map((li, lineItemIndex) => (
                                      <Draggable key={`${li.id}_s2`} draggableId={`${index}_${lineItemIndex}_s2`} index={lineItemIndex} isDragDisabled={disabled}>
                                        {(provided: any) => (
                                          <Grid item xs={12} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <Accordion>
                                              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                                {hintValidationAlert(_.get(validationErrors, `contentBlocks[${index}].lineItems[${lineItemIndex}]`))}
                                                <Typography>
                                                  <FormLabel
                                                    name={`contentBlocks.${index}.lineItems.${lineItemIndex}`}
                                                    control={control}
                                                    render={(lineItem: OfferTemplateLineItem) => (
                                                      <>
                                                        {lineItem.count || 1}x {lineItem.header}
                                                      </>
                                                    )}
                                                  />
                                                </Typography>
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                <EditorAccordionLineItem
                                                  contentBlock={contentBlock}
                                                  contentBlockIndex={index}
                                                  lineItemIndex={lineItemIndex}
                                                  control={control}
                                                  disabled={disabled}
                                                  onLineItemChanged={onLineItemChanged}
                                                  onLineItemPriceComponentChanged={onLineItemPriceComponentChanged}
                                                />
                                                {!disabled && <Button
                                                  onClick={() => {
                                                    lineItemsRemove(lineItemIndex);
                                                    onTotalsChanged();
                                                  }}
                                                  startIcon={<DeleteIcon />}
                                                >
                                                  {i18next.t('offerblock-lineitem-delete')}
                                                </Button>}
                                              </AccordionDetails>
                                            </Accordion>
                                          </Grid>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </Grid>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {!disabled && <Grid item xs={12}>
                      <Select
                        value={0}
                        displayEmpty
                        onChange={event => {
                          if (!event.target.value) return;

                          const sku = `${event.target.value}`;
                          const li = onLineItemSKUAdded(index, sku);
                          if (li) {
                            lineItemsAppend(li);
                            onTotalsChanged();
                          }
                        }}
                      >
                        <MenuItem value={0}>
                          <em>{i18next.t('offerblock-lineitem-addsku')}</em>
                        </MenuItem>
                        {quickPriceCalculator &&
                          quickPriceCalculator.allProductsDb &&
                          quickPriceCalculator.allProductsDb.map((p, index) => (
                            <MenuItem key={index} value={p.sku}>
                              {p.sku} {p.name}
                            </MenuItem>
                          ))}
                        <MenuItem value={''}>
                          <em>---------------------------</em>
                        </MenuItem>
                        {quickPriceCalculator &&
                          quickPriceCalculator.allBundlesDb &&
                          quickPriceCalculator.allBundlesDb.map((b, index) => (
                            <MenuItem key={index} value={b.sku}>
                              {b.sku} {b.name}
                            </MenuItem>
                          ))}
                        <MenuItem value={''}>
                          <em>---------------------------</em>
                        </MenuItem>
                        {quickPriceCalculator &&
                          quickPriceCalculator.allFacilitiesDb &&
                          quickPriceCalculator.allFacilitiesDb.map((f, index) => (
                            <MenuItem key={index} value={f.sku}>
                              {f.sku} {f.name}
                            </MenuItem>
                          ))}
                      </Select>
                      <Select
                        value={0}
                        displayEmpty
                        onChange={event => {
                          if (!event.target.value) return;

                          const li = onLineItemFreeAdded(index, event.target.value as any);
                          if (li) {
                            lineItemsAppend(li);
                            onTotalsChanged();
                          }
                        }}
                      >
                        <MenuItem value={0}>
                          <em>{i18next.t('offerblock-lineitem-addfree')}</em>
                        </MenuItem>
                        {quickPriceCalculator &&
                          quickPriceCalculator.taxRateSelector &&
                          quickPriceCalculator.taxRateSelector.taxTypes.map((t, index) => (
                            <MenuItem key={index} value={t.id}>
                              {t.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {onDelete && ((contentBlock as any).day === -1 || (watch('contentBlocks').filter(c => (c.type === 'LINE_ITEMS' || c.type === 'CANCELLATION_ITEMS') && (c as any).day >= 0).length > 1 && index === watch('contentBlocks').map(c => c.type === 'LINE_ITEMS' || c.type === 'CANCELLATION_ITEMS').lastIndexOf(true))) && (
                        <Button onClick={() => onDelete(index)} startIcon={<DeleteIcon />}>
                          {i18next.t('offerblock-delete-day')}
                        </Button>
                      )}
                    </Grid>}
                  </Grid>
                </>
              )}

              {!disabled && onDelete && contentBlock.type === 'PLAIN_HTML' && (
                <Button onClick={() => onDelete(index)} startIcon={<DeleteIcon />}>
                  {i18next.t('offerblock-delete')}
                </Button>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

interface IEditorAccordionDummyProps {
  cssStyles: string;
  contentBlock: OfferTemplateContentBlock;
  index: number;
  control: any;
  disabled?: boolean;
  onDelete?: (index: number) => void;
}

export function EditorAccordionDummy({ cssStyles, contentBlock, index, control, disabled, onDelete }: IEditorAccordionDummyProps) {
  const [tabValue, setTabValue] = React.useState<string | null>('data');

  const {
    watch,
    formState: { errors: validationErrors },
  } = useFormContext<OfferTemplateInput>();

  const {
    fields: formFieldsFields,
    append: formFieldsAppend,
    remove: formFieldsRemove,
    replace: formFieldsReplace,
  } = useFieldArray({
    control,
    name: `contentBlocks.${index}.formFields`,
  });

  const _reassignSequence = (formFields: OfferTemplateFormField[]) => {
    formFields.forEach((li, index) => {
      li.sequence = index + 1;
    });
    formFieldsReplace(formFields);
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>
            {contentBlock.type === 'CUSTOM_FORM' && (
              <>
                <span>
                  {i18next.t('offerblock-customform-header')} #{contentBlock.sequence}
                </span>
                <br />
                <span>
                  <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                </span>
              </>
            )}
            {contentBlock.type === 'PLAIN_HTML' && (
              <>
                <span>
                  {i18next.t('offerblock-plainhtml-header')} #{contentBlock.sequence}
                </span>
                <br />
                <span>
                  <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                </span>
              </>
            )}
            {contentBlock.type === 'LINE_ITEMS' && (
              <>
                <span>
                  {i18next.t('offerblock-lineitems-header')} #{contentBlock.sequence}
                </span>
                <br />
                <span>
                  <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                </span>
              </>
            )}
            {contentBlock.type === 'CANCELLATION_ITEMS' && (
              <>
                <span>
                  {i18next.t('offerblock-cancellationitems-header')} #{contentBlock.sequence}
                </span>
                <br />
                <span>
                  <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                </span>
              </>
            )}
            {contentBlock.type === 'INPUT_DAYS' && (
              <>
                <span>
                  {i18next.t('offerblock-inputdays-header')} #{contentBlock.sequence}
                </span>
                <br />
                <span>
                  <em>{watch(`contentBlocks.${index}.blockDescription`)}</em>
                </span>
              </>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {contentBlock.type === 'CUSTOM_FORM' && (
                <>
                  <Tabs value={tabValue}>
                    <Tab value="data" label={i18next.t('offerblock-customform-data-tab')} onClick={() => setTabValue('data')} />
                    <Tab value="template" label={i18next.t('offerblock-customform-template-tab')} onClick={() => setTabValue('template')} />
                  </Tabs>
                  {tabValue === 'template' && (
                    <Grid container>
                      <Grid item xs={12}>
                        <FormInputText
                          name={`contentBlocks.${index}.template`}
                          label={i18next.t('offerblock-customform-html')}
                          required
                          textFieldProps={{
                            multiline: true,
                            rows: 10,
                          }}
                          control={control}
                          disabled={disabled}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {tabValue === 'data' && (
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <br />
                        <FormInputText
                          name={`contentBlocks.${index}.blockDescription`}
                          label={i18next.t('offerblock-description')}
                          control={control}
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DragDropContext
                          onDragEnd={result => {
                            if (result.destination && contentBlock.lineItems) {
                              const formFields = watch(`contentBlocks.${index}.formFields`);
                              if (!formFields) return;
                              const [reorderedItem] = formFields.splice(result.source.index, 1);
                              formFields.splice(result.destination.index, 0, reorderedItem);
                              _reassignSequence(formFields);
                            }
                          }}
                        >
                          <Droppable droppableId={`characters_${index}`}>
                            {(provided: any) => (
                              <Grid container spacing={3} {...provided.droppableProps} ref={provided.innerRef} className={`characters_${index}`}>
                                {formFieldsFields.map((li, formFieldIndex) => (
                                  <Draggable
                                    key={`${li.id}_s2`}
                                    draggableId={`${index}_${formFieldIndex}_s2`}
                                    index={formFieldIndex}
                                    isDragDisabled={disabled}
                                  >
                                    {(provided: any) => (
                                      <Grid item xs={12} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <Accordion>
                                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                            <Typography>
                                              {watch(`contentBlocks.${index}.formFields.${formFieldIndex}.label`)} -{' '}
                                              {watch(`contentBlocks.${index}.formFields.${formFieldIndex}.name`)}
                                              <br />
                                              <span>
                                                <em>
                                                  {i18next.t(
                                                    `enums-EOfferVersionContentBlockFormFieldType-${watch(
                                                      `contentBlocks.${index}.formFields.${formFieldIndex}.type`,
                                                    )}`,
                                                  )}
                                                </em>
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <EditorAccordionFormFieldDummy
                                              cssStyles={cssStyles}
                                              contentBlock={contentBlock}
                                              contentBlockIndex={index}
                                              formFieldIndex={formFieldIndex}
                                              control={control}
                                              disabled={disabled}
                                            />
                                            {!disabled && (
                                              <Button
                                                onClick={() => {
                                                  formFieldsRemove(formFieldIndex);
                                                }}
                                                startIcon={<DeleteIcon />}
                                              >
                                                {i18next.t('offerblock-formfield-delete')}
                                              </Button>
                                            )}
                                          </AccordionDetails>
                                        </Accordion>
                                      </Grid>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </Grid>
                            )}
                          </Droppable>
                        </DragDropContext>
                        {!disabled && (
                          <Button
                            onClick={() => {
                              formFieldsAppend({
                                type: 'TEXT',
                                label: '',
                                sequence: 0,
                              });
                            }}
                          >
                            {i18next.t('offerblock-formfield-append')}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {contentBlock.type === 'PLAIN_HTML' && (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <br />
                    <FormInputText
                      name={`contentBlocks.${index}.blockDescription`}
                      label={i18next.t('offerblock-description')}
                      control={control}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputRichText
                      name={`contentBlocks.${index}.template`}
                      label={i18next.t('offerblock-plainhtml-html')}
                      required
                      options={{
                        content_style: cssStyles,
                      }}
                      control={control}
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
              )}
              {(contentBlock.type === 'LINE_ITEMS' || contentBlock.type === 'CANCELLATION_ITEMS') && (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <br />
                    <FormInputText
                      name={`contentBlocks.${index}.blockDescription`}
                      label={i18next.t('offerblock-description')}
                      control={control}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      name={`contentBlocks.${index}.template`}
                      label={i18next.t('offerblock-plainhtml-html')}
                      required
                      textFieldProps={{
                        multiline: true,
                        rows: 10,
                      }}
                      control={control}
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
              )}
              {contentBlock.type === 'INPUT_DAYS' && (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <br />
                    <FormInputText
                      name={`contentBlocks.${index}.blockDescription`}
                      label={i18next.t('offerblock-description')}
                      control={control}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      name={`contentBlocks.${index}.template`}
                      label={i18next.t('offerblock-plainhtml-html')}
                      required
                      textFieldProps={{
                        multiline: true,
                        rows: 10,
                      }}
                      control={control}
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
              )}

              {!disabled && onDelete && (
                <Button onClick={() => onDelete(index)} startIcon={<DeleteIcon />}>
                  {i18next.t('offerblock-delete')}
                </Button>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
