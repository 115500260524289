import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, ListSubheader, Divider, FormHelperText, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormSelectInputProps, FormSelectOption } from './FormInputProps';
import { allCountriesSorted, allLanguages } from 'languages';
import i18next from 'i18next';

const FilterTextField = ({ filterValue, setFilterValue, filterRef }: { filterValue: string, setFilterValue: (value: string) => void, filterRef: React.RefObject<HTMLInputElement> }) => {
  return (
    <MenuItem sx={{ p:1 }} onClickCapture={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
    onKeyDown={e => e.stopPropagation()}>
      <TextField inputRef={filterRef} placeholder='Filter' inputProps={{
        sx: { p: 1, root: { border: '0px' } },
      }} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }} fullWidth value={filterValue} onChange={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setFilterValue(e.target.value)
        setTimeout(() => filterRef.current?.focus(), 10);
      }}
      />
    </MenuItem>
  );
}

export const buildMenuItemsFromOptions = (options: FormSelectOption[], onSelect?: (value?: any) => any) => {
  return options.map((option, index) => {
    if (option.divider) {
      return <ListSubheader key={index}>{option.label || <Divider />}</ListSubheader>;
    } else {
      return (
        <MenuItem
          key={index}
          disabled={!!option.disabled}
          value={option.value}
          {...(!option.disabled && onSelect ? { onClick: () => onSelect(option.value) } : {})}
        >
          {option.label}
        </MenuItem>
      );
    }
  });
};

export const FormInputDropdown = ({ name, control, label, disabled, options, helperText, onSelected, enableFilter }: FormSelectInputProps) => {

  const [filterValue, setFilterValue] = React.useState('');
  const filterRef = React.useRef<HTMLInputElement>(null);

  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-label`} disabled={disabled}>{label}</InputLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <Select
              fullWidth
              label={label}
              {...(typeof disabled !== 'undefined' ? { disabled } : {})}
              labelId={`${name}-label`}
              onChange={(...args) => {
                onChange(...args);
                if (onSelected) {
                  onSelected(args[0].target.value);
                }
              }}
              error={!!error}
              value={value}
            >
              {enableFilter && <FilterTextField filterValue={filterValue} setFilterValue={setFilterValue} filterRef={filterRef} />}
              {buildMenuItemsFromOptions(options.filter(o => o.divider || (o.label && o.label.toString().toLowerCase().includes(filterValue.toLowerCase()))))}
            </Select>
            <FormHelperText error={!!error}>{error ? error.message : helperText ? helperText : ' '}</FormHelperText>
          </>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};

interface SimpleDropdownProps {
  value: any;
  name: string;
  label: string;
  disabled?: boolean;
  options: FormSelectOption[];
  helperText?: string;
  enableFilter?: boolean;
  onChange: (value: any) => void;
}

export const SimpleDropdown = ({ value, name, label, disabled, options, helperText, onChange, enableFilter }: SimpleDropdownProps) => {

  const [filterValue, setFilterValue] = React.useState('');
  const filterRef = React.useRef<HTMLInputElement>(null);

  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        fullWidth
        label={label}
        {...(typeof disabled !== 'undefined' ? { disabled } : {})}
        labelId={`${name}-label`}
        onChange={onChange}
        value={value}
      >
        {enableFilter && <FilterTextField filterValue={filterValue} setFilterValue={setFilterValue} filterRef={filterRef} />}
        {buildMenuItemsFromOptions(options.filter(o => o.divider || (o.label && o.label.toString().toLowerCase().includes(filterValue.toLowerCase()))))}
      </Select>
      <FormHelperText>{helperText || ' '}</FormHelperText>
    </FormControl>
  );
};

export const FormInputCountryDropdown = (props: Omit<FormSelectInputProps, 'options'>) => {
  return (<FormInputDropdown
    options={[
      ...(!props.required ? [{
        label: <em>{i18next.t('generic-selection-empty')}</em>,
        value: null,
      }] : []),
      ...allCountriesSorted.map(c => (c ? { value: c.code, label: c.label } : ({ divider: true } as any))),
    ]}
    {...props}
  />)
}

export const FormInputLanguageDropdown = (props: Omit<FormSelectInputProps, 'options'>) => {
  return (<FormInputDropdown
    options={[
      ...(!props.required ? [{
        label: <em>{i18next.t('generic-selection-empty')}</em>,
        value: null,
      }] : []),
      ...allLanguages.map(l => (l ? { value: l.code, label: l.label } : ({ divider: true } as any))),
    ]}
    {...props}
  />)
}
