import { useSelector } from 'react-redux';
import { IUser, ISpace, IBoundToSpace, IHotelFilter } from '../types';
import { UserFullFragment, SpaceFullFragment, HotelListShortFragment } from '__generated__/graphql';

export const initialSpaceId = (user: IUser, filter?: IHotelFilter | null, defaultSpaceId?: number | null) => {
  if (filter && filter.spaceId && canEditSpaceId(user, filter.spaceId)) return filter.spaceId;
  else if (user && user.space && user.space.id) return user.space.id;
  else if (defaultSpaceId !== undefined && defaultSpaceId !== null) return defaultSpaceId;
  else if (user && user.spaces && user.spaces.length > 0) return user.spaces[0].id;
  return null;
};
export const initialSpace = (user: IUser, filter?: IHotelFilter | null, defaultSpaceId?: number | null) => {
  const spaceId = initialSpaceId(user, filter, defaultSpaceId);
  if (spaceId) return user.spaces.find(s => s.id === spaceId) || null;
  else return null;
};

export const user2IUser = (user: UserFullFragment, spaces: SpaceFullFragment[], hotels: HotelListShortFragment[]): IUser => {
  const memberships = user.memberships.filter(m => user.space && user.space.id !== m.space?.id);
  const result: IUser = {
    id: user.id,
    name: user.name,
    email: user.email,
    isAdmin: !!user.isAdmin,
    isRoot: user.space ? false : true,
    isSingleSpace: false,
    isSingleAdminSpace: false,
    space: user.space || null,
    memberships: memberships,
    spaces: spaces,
    hotels: hotels,
  };
  if (user.space) {
    if (user.space.activeSEMPlan && user.space.activeSEMPlan.semPlan.flags.multiTenancy) {
      result.isSingleSpace = spaces.filter(s => user.space!.id !== s.id).length === 0;
      result.isSingleAdminSpace = spaces.filter(s => user.space!.id !== s.id && canEditAdminSpace(result, s)).length === 0;
    } else {
      result.isSingleSpace = true;
      result.isSingleAdminSpace = canEditAdminSpace(result, user.space);
    }
  }
  return result;
};

export const userSelector = () => {
  return useSelector<any, IUser | null>(state => {
    return state.user.value || null;
  });
};

export const canEditRecord = (user: IUser | undefined | null, record: IBoundToSpace) => {
  if (!user) return false;
  if (user.isRoot) return true;
  if (!record.space || !record.space.id) return false;

  const space = user.spaces.find(s => s.id === record.space!.id);
  if (!space) return false;

  if (user.space && space.id === user.space.id) return true;
  if (user.memberships.findIndex(m => m.space?.id === space.id) >= 0) return true;
  if (space.parent) return canEditAdminSpace(user, space.parent);
  return false;
};
export const canEditSpace = (user: IUser | undefined | null, space: ISpace) => {
  return canEditRecord(user, { space });
};
export const canEditSpaceId = (user: IUser | undefined | null, spaceId: number) => {
  return canEditRecord(user, { space: user?.spaces.find(s => s.id === spaceId) });
};

export const hasAnyAdminSpace = (user: IUser | undefined | null): boolean => {
  if (!user) return false;
  if (user.isRoot) return true; // root user
  if (user.isAdmin) return true;
  if (user.memberships.filter(m => m.isAdmin).length > 0) return true;
  return false;
};

export const canEditAdminRecord = (user: IUser | undefined | null, record: IBoundToSpace): boolean => {
  if (!user) return false;
  if (user.isAdmin) return true;
  if (!record.space || !record.space.id) return false;

  const space = user.spaces.find(s => s.id === record.space!.id);
  if (!space) return false;

  if (user.space && space.id === user.space.id && user.isAdmin) return true;
  if (user.memberships.findIndex(m => m.isAdmin && m.space?.id === space.id) >= 0) return true;
  if (space.parent) return canEditAdminSpace(user, space.parent);
  return false;
};
export const canEditAdminSpace = (user: IUser | undefined | null, space: ISpace) => {
  return canEditAdminRecord(user, { space });
};
export const canEditAdminSpaceId = (user: IUser | undefined | null, spaceId: number) => {
  return canEditAdminRecord(user, { space: user?.spaces.find(s => s.id === spaceId) });
};

const _flattenChildren = (space: ISpace): number[] => {
  return (space.children || []).reduce<number[]>(
    (acc, child) => acc.concat(_flattenChildren(child)),
    [space.id]
  );
};
const _flattenParents = (space: ISpace): number[] => {
  return [
    ...(space.parent ?_flattenParents(space.parent) : []),
    space.id
  ]
};

export const filterForSelectableRecords  = <TList extends IBoundToSpace>(user: IUser | undefined | null, list: TList[], spaceId: number, includeSuccessors: boolean, includeAncestors: boolean): TList[] => {
  if (!user) return []
  const validSpaceIds = [spaceId]
  const space = user.spaces.find(s => s.id === spaceId)
  if (space && includeSuccessors) {
    validSpaceIds.push(..._flattenChildren(space))
  }
  if (space && includeAncestors) {
    validSpaceIds.push(..._flattenParents(space))
  }
  return list.filter(l => l.space && validSpaceIds.indexOf(l.space.id) >= 0)
}