import _ from 'lodash';
import moment from 'moment';
import { handlebars, TResolveMediaSrc } from '../utils/handlebars';
import { formatDate, formatPrice } from '../utils/format';

export type EWL_OfferVersionContentBlockType = "LINE_ITEMS" | "CANCELLATION_ITEMS" | "PLAIN_HTML" | "INPUT_DAYS" | "CUSTOM_FORM";
export type EWL_OfferDocumentType = "OFFER" | "RESERVATION" | "INVOICE";
export type EWL_OfferRoomOccupancy = "MORNING" | "AFTERNOON" | "FULLDAY";

export const mapOfferStatusToDocumentType = (offerStatus: string, offerVersionStatus: string): EWL_OfferDocumentType => {
  if (offerStatus === 'INVOICE' || offerStatus === 'CLOSED') return 'INVOICE';
  if (offerStatus === 'APPROVED' || offerStatus === 'REJECTED' || offerStatus === 'ACTIVE') return 'RESERVATION';
  if (offerStatus === 'PENDING' && offerVersionStatus === 'WAITFORCLIENTAPPROVAL') return 'RESERVATION';
  return 'OFFER'
};

interface IOffer {
  contentBlocks: IContentBlock[];
  agreedCancellationWaiveNet?: number | null;
  agreedCancellationWaiveGross?: number | null;
  manualCancellationWaiveNet?: number | null;
  manualCancellationWaiveGross?: number | null;
}

interface IContentBlock {
  type: EWL_OfferVersionContentBlockType;
  lineItems?: ILineItem[] | null
}

interface ILineItem {
  priceGross: number;
  priceNet: number;
  taxes: ITax[];
}

interface ITax {
  taxRateId: number;
  name: string;
  rate: number;
  price: number;
}

export interface OfferTemplateInput extends IOffer {
  documentType: EWL_OfferDocumentType;
  pageOptions: OfferTemplatePageOptions;
  includeEmptyLineItems: boolean;
  date: Date;
  startDate: Date;
  expirationDate: Date;
  client: OfferTemplateClient;
  hotel: OfferTemplateHotel;
  priceList?: OfferTemplatePriceList | null;
  serviceType?: OfferTemplateServiceType | null;
  contentBlocks: OfferTemplateContentBlock[];
  totalPriceNet: number;
  totalPriceGross: number;
  totalTax: number;
  totalTaxes: OfferTemplateTax[];
  additionalInfo1?: string | null;
  additionalInfo2?: string | null;
  additionalInfo3?: string | null;
}

export interface OfferTemplateInputFormatted extends OfferTemplateInput {
  spaceId: number;
  dateFormatted: string;
  expirationDateFormatted: string;
  contentBlocks: OfferTemplateContentBlockFormatted[];
  totalPriceNetFormatted: string;
  totalPriceGrossFormatted: string;
  agreedCancellationWaiveNetFormatted?: string | null;
  agreedCancellationWaiveGrossFormatted?: string | null;
  manualCancellationWaiveNetFormatted?: string | null;
  manualCancellationWaiveGrossFormatted?: string | null;
  totalTaxFormatted: string;
  totalTaxes: OfferTemplateTaxFormatted[];
  additionalInfo1?: string | null;
  additionalInfo2?: string | null;
  additionalInfo3?: string | null;
}

export interface OfferTemplatePageOptions {
  title?: string | null;
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;
  cssStyles?: string | null;
  headerTemplate?: string | null;
  footerTemplate?: string | null;
}

export interface OfferTemplateInputDayRoomOccupancy {
  room: number;
  occupancy: string;
  seating?: string | null;
}

export interface OfferTemplateInputDayAddon {
  sku: string;
  count?: number | null;
  header: string;
  details?: string | null;
}

export interface OfferTemplateInputDay {
  day: number;
  totalGuests: number;
  overnightGuests: number;

  occupancy: OfferTemplateInputDayRoomOccupancy[];
  addons: OfferTemplateInputDayAddon[];
}

export interface OfferTemplateCancellationRule {
  daysToEvent: number;
  rate: number;
}

interface OfferTemplateCancellationRuleFormatted extends OfferTemplateCancellationRule {
  daysToEventFormatted: string;
  rateFormatted: string;
}

export interface OfferTemplateContentBlock extends IContentBlock {
  template: string;
  sequence: number;
  blockDescription?: string | null;
  inputDay?: OfferTemplateInputDay | null;
  lineItems?: OfferTemplateLineItem[] | null;
  formFields?: OfferTemplateFormField[] | null;
  cancellationRule?: OfferTemplateCancellationRule | null;
}

interface OfferTemplateContentBlockFormatted extends OfferTemplateContentBlock {
  inputDay?: OfferTemplateInputDayFormatted | null;
  lineItems: OfferTemplateLineItemFormatted[];
  cancellationRule?: OfferTemplateCancellationRuleFormatted | null;
}

export interface OfferTemplateClient {
  firstname: string;
  lastname: string;
  company: string;
  email: string;
  address: string;
  city: string;
  zip: string;
  country: string;
  phone?: string | null | undefined;
  billingFirstname: string;
  billingLastname: string;
  billingCompany: string;
  billingAddress: string;
  billingCity: string;
  billingZip: string;
  billingCountry: string;
}

export interface OfferTemplateHotel {
  name: string;
  refCode: string;
  businessName?: string | null;
  businessAddress1?: string | null;
  businessAddress2?: string | null;
  businessAddress3?: string | null;
  businessAddress4?: string | null;
}

export interface OfferTemplatePriceList {
  name: string;
  refCode: string;
  version: string;
  currency: string;
}

export interface OfferTemplateServiceType {
  sku: string;
  name: string;
  header: string;
  details: string;
}

export interface OfferTemplateLineItem extends ILineItem {
  day: number;
  sku?: string | null;
  header: string;
  details?: string | null;
  count: number;
  priceItem: number;
  priceGross: number;
  priceNet: number;
  components: OfferTemplatePriceComponent[];
  taxes: OfferTemplateTax[];
  sequence: number;
}

export interface OfferTemplateFormField {
  sequence: number;
  label: string;
  type: string;
  name: string;
  defaultValue?: string | null;
  value?: string | null;
  options?: string | null;
  required?: boolean | null;
  placeholder?: string | null;
  helpText?: string | null;
}

interface OfferTemplateLineItemFormatted extends OfferTemplateLineItem {
  dayFormatted: string;
  date: Date;
  dateFormatted: string;
  priceItemFormatted: string;
  priceItemGross: number;
  priceItemGrossFormatted: string;
  priceItemNet: number;
  priceItemNetFormatted: string;
  priceGrossFormatted: string;
  priceNetFormatted: string;
  components: OfferTemplatePriceComponentFormatted[];
  taxes: OfferTemplateTaxFormatted[];
}

interface OfferTemplateInputDayFormatted extends OfferTemplateInputDay {
  dayFormatted: string;
  date: Date;
  dateFormatted: string;
  occupancy: OfferTemplateInputDayRoomOccupancyFormatted[];
  addons: OfferTemplateInputDayAddonFormatted[];
}

interface OfferTemplateInputDayRoomOccupancyFormatted extends OfferTemplateInputDayRoomOccupancy {
  occupancyFormatted: string;
  seatingFormatted: string;
}

interface OfferTemplateInputDayAddonFormatted extends OfferTemplateInputDayAddon {
  count: number;
  sku: string;
  header: string;
  details: string;
}

export interface OfferTemplateTax extends ITax {
}

interface OfferTemplateTaxFormatted extends OfferTemplateTax {
  rateFormatted: string;
  ratePercent: number;
  ratePercentFormatted: string;
  priceFormatted: string;
}

export interface OfferTemplatePriceComponent {
  name: string;
  price: number;
}

interface OfferTemplatePriceComponentFormatted extends OfferTemplatePriceComponent {
  priceFormatted: string;
}

const calculateTotalByContentBlocks = (contentBlocks: IContentBlock[]) => {
  let totalPriceNet = 0;
  let totalPriceGross = 0;
  const totalTaxes = [] as ITax[];
  for (const cb of contentBlocks) {
    if (!cb.lineItems) continue;
    for (const li of cb.lineItems) {
      totalPriceNet += li.priceNet;
      totalPriceGross += li.priceGross;
      for (const tax of li.taxes || []) {
        const existingTax = totalTaxes.find(t => t.name === tax.name);
        if (existingTax) {
          existingTax.price += tax.price;
        } else {
          totalTaxes.push({ ...tax });
        }
      }
    }
  }

  return {
    totalPriceNet,
    totalPriceGross,
    totalTax: totalPriceGross - totalPriceNet,
    totalTaxes,
  };
};

export const calculateTotals = (offer: IOffer) => {
  let { totalPriceNet, totalPriceGross, totalTaxes } = calculateTotalByContentBlocks(offer.contentBlocks.filter(cb => cb.type === 'LINE_ITEMS'));
  let { totalPriceNet: cancellationFeeNet, totalPriceGross: cancellationFeeGross, totalTaxes: cancellationTaxes } = calculateTotalByContentBlocks(offer.contentBlocks.filter(cb => cb.type === 'CANCELLATION_ITEMS'));

  if (cancellationFeeNet > 0) {
    const cancellationWaiveGross = (offer.agreedCancellationWaiveGross || 0) + (offer.manualCancellationWaiveGross || 0)
    const cancellationWaiveNet = (offer.agreedCancellationWaiveNet || 0) + (offer.manualCancellationWaiveNet || 0)

    if (cancellationWaiveNet > 0) {
      if (cancellationWaiveNet < cancellationFeeNet) {
        const finalCancellationFeeNet = cancellationFeeNet - cancellationWaiveNet
        const ratio = finalCancellationFeeNet / cancellationFeeNet

        cancellationFeeGross = cancellationFeeGross * ratio
        cancellationFeeNet = cancellationFeeNet * ratio
        for (const ctax of cancellationTaxes) {
          ctax.price = ctax.price * ratio
        }
      } else {
        cancellationFeeGross = 0
        cancellationFeeNet = 0
        cancellationTaxes.splice(0, cancellationTaxes.length)
      }
    } else if (cancellationWaiveGross > 0) {
      if (cancellationWaiveGross < cancellationFeeGross) {
        const finalCancellationFeeGross = cancellationFeeGross - cancellationWaiveGross
        const ratio = finalCancellationFeeGross / cancellationFeeGross

        cancellationFeeGross = cancellationFeeGross * ratio
        cancellationFeeNet = cancellationFeeNet * ratio
        for (const ctax of cancellationTaxes) {
          ctax.price = ctax.price * ratio
        }
      } else {
        cancellationFeeGross = 0
        cancellationFeeNet = 0
        cancellationTaxes.splice(0, cancellationTaxes.length)
      }
    } 

    totalPriceNet = totalPriceNet + cancellationFeeNet
    totalPriceGross = totalPriceGross + cancellationFeeGross

    for (const ctax of cancellationTaxes) {
      const existingTax = totalTaxes.find(t => t.name === ctax.name);
      if (existingTax) {
        existingTax.price += ctax.price;
      } else {
        totalTaxes.push({ ...ctax });
      }
    }
  }
  return {
    totalPriceNet,
    totalPriceGross,
    totalTax: totalPriceGross - totalPriceNet,
    totalTaxes,
  };
}

const _formatPrice = (price: number, priceList?: OfferTemplatePriceList | null) => {
  return formatPrice(price, 'de-AT', (priceList && priceList.currency) || 'EUR');
};
const _formatDate = (date: Date) => {
  return formatDate(date, 'de-AT');
};

export const formatOfferDay = (day: number) => (day < 0 ? 'Vortag' : 'Tag ' + (day + 1));

export const generateDummyOfferTemplateInput = (
  pageOptions: OfferTemplatePageOptions,
  contentBlocks: OfferTemplateContentBlock[],
): OfferTemplateInput => {
  const offer: OfferTemplateInput = {
    documentType: 'OFFER',
    pageOptions: pageOptions,
    includeEmptyLineItems: false,
    date: new Date(),
    startDate: new Date(),
    expirationDate: moment(new Date()).add(3, 'days').toDate(),
    additionalInfo1: 'Zusatzinfo 1',
    additionalInfo2: 'Zusatzinfo 2',
    additionalInfo3: 'Zusatzinfo 3',
    client: {
      firstname: 'Max',
      lastname: 'Mustermann',
      company: 'Musterfirma',
      address: 'Musterstraße 1',
      city: 'Musterstadt',
      zip: '12345',
      country: 'Deutschland',
      phone: '+43 123 123 123',
      billingFirstname: 'Max',
      billingLastname: 'Mustermann',
      billingCompany: 'Musterfirma',
      billingAddress: 'Musterstraße 1',
      billingCity: 'Musterstadt',
      billingZip: '12345',
      billingCountry: 'Deutschland',
      email: 'max.mustermann@example.com',
    },
    hotel: {
      name: 'Musterhotel',
      refCode: 'M123123',
      businessName: 'Musterhotel',
      businessAddress1: 'Musterstraße 1',
      businessAddress2: '12345 Musterstadt',
      businessAddress3: 'Deutschland',
    },
    priceList: {
      name: 'Preisliste',
      version: '1.0',
      refCode: 'P123123',
      currency: 'EUR',
    },
    serviceType: {
      name: 'Service-Variante 1',
      sku: 'S123123',
      header: 'Service-Variante 1',
      details: 'Service Variante 1 Details',
    },
    contentBlocks: [...contentBlocks],
    totalPriceNet: 0,
    totalPriceGross: 0,
    agreedCancellationWaiveNet: 10,
    agreedCancellationWaiveGross: 12,
    manualCancellationWaiveNet: 5,
    manualCancellationWaiveGross: 6,
    totalTax: 0,
    totalTaxes: [
      {
        taxRateId: 1,
        name: 'Standardsteuersatz',
        price: 50.5,
        rate: 0.2,
      },
    ],
  };

  const inputDaysContentBlock = offer.contentBlocks.find(cb => cb.type === 'INPUT_DAYS' && (!cb.lineItems || cb.lineItems.length === 0));
  const inputDaysContentBlockIndex = offer.contentBlocks.findIndex(cb => cb.type === 'INPUT_DAYS' && (!cb.lineItems || cb.lineItems.length === 0));

  if (inputDaysContentBlock) {
    const inputDaysBlocks = [];
    for (let i = -1; i < 3; i++) {
      const dayProd = {
        day: i,
        dayFormatted: formatOfferDay(i),
        date: moment(offer.startDate).add(i, 'days').toDate(),
        dateFormatted: _formatDate(moment(offer.startDate).add(i, 'days').toDate()),
        totalGuests: 1,
        overnightGuests: 1,
        occupancy: [
          {
            room: 1,
            occupancy: 'MORNING',
            seating: 'UFORM',
          },
        ],
        addons: [
          {
            sku: 'P123123',
            header: 'Zusatzleistung 1',
            details: 'Zusatzleistung 1 Details',
            count: 1,
          },
        ],
      };

      const inputDaysContentBlockClone = _.cloneDeep(inputDaysContentBlock);
      inputDaysContentBlockClone.inputDay = dayProd;
      inputDaysBlocks.push(inputDaysContentBlockClone);
    }

    offer.contentBlocks.splice(inputDaysContentBlockIndex, 1, ...inputDaysBlocks);
  }

  const lineItemsContentBlock = offer.contentBlocks.find(cb => cb.type === 'LINE_ITEMS' && (!cb.lineItems || cb.lineItems.length === 0));
  const lineItemsContentBlockIndex = offer.contentBlocks.findIndex(cb => cb.type === 'LINE_ITEMS' && (!cb.lineItems || cb.lineItems.length === 0));

  if (lineItemsContentBlock) {
    const lineItemsBlocks = [];
    for (let i = -1; i < 3; i++) {
      const dayProd = {
        day: i,
        dayFormatted: formatOfferDay(i),
        date: moment(offer.startDate).add(i, 'days').toDate(),
        dateFormatted: _formatDate(moment(offer.startDate).add(i, 'days').toDate()),
        count: 1,
        priceItem: 100.15,
        priceItemFormatted: _formatPrice(100.15, offer.priceList),
        priceNet: 100.15,
        priceNetFormatted: _formatPrice(100.15, offer.priceList),
        priceGross: 119.15,
        priceGrossFormatted: _formatPrice(119.15, offer.priceList),
        components: [
          {
            name: 'Standardsteuersatz',
            price: 10.5,
          },
        ],
        taxes: [
          {
            taxRateId: 1,
            name: 'Standardsteuersatz',
            price: 10.5,
            rate: 0.2,
          },
        ],
      };

      const lineItemsContentBlockClone = _.cloneDeep(lineItemsContentBlock);
      lineItemsContentBlockClone.lineItems = [
        {
          ..._.cloneDeep(dayProd),
          sku: 'P123123',
          header: 'Produkt 1',
          details: 'Produkt Details 1',
          sequence: 1,
        },
        {
          ..._.cloneDeep(dayProd),
          sku: 'P123124',
          header: 'Produkt 2',
          details: 'Produkt Details 2',
          sequence: 2,
        },
      ];
      lineItemsBlocks.push(lineItemsContentBlockClone);
    }

    offer.contentBlocks.splice(lineItemsContentBlockIndex, 1, ...lineItemsBlocks);
  }

  const cancellationItemsContentBlock = offer.contentBlocks.find(cb => cb.type === 'CANCELLATION_ITEMS' && (!cb.lineItems || cb.lineItems.length === 0));
  const cancellationItemsContentBlockIndex = offer.contentBlocks.findIndex(cb => cb.type === 'CANCELLATION_ITEMS' && (!cb.lineItems || cb.lineItems.length === 0));

  if (cancellationItemsContentBlock) {
    const cancellationItemsContentBlockClone = _.cloneDeep(cancellationItemsContentBlock);
    cancellationItemsContentBlockClone.cancellationRule = {
      daysToEvent: 60,
      rate: 0.3
    }
    cancellationItemsContentBlockClone.lineItems = []

    for (let i = -1; i < 3; i++) {
      const dayProd = {
        day: i,
        dayFormatted: formatOfferDay(i),
        date: moment(offer.startDate).add(i, 'days').toDate(),
        dateFormatted: _formatDate(moment(offer.startDate).add(i, 'days').toDate()),
        count: 1,
        priceItem: 33.15,
        priceItemFormatted: _formatPrice(33.15, offer.priceList),
        priceNet: 33.15,
        priceNetFormatted: _formatPrice(33.15, offer.priceList),
        priceGross: 40.15,
        priceGrossFormatted: _formatPrice(40.15, offer.priceList),
        components: [
          {
            name: 'Standardsteuersatz',
            price: 3.5,
          },
        ],
        taxes: [
          {
            taxRateId: 1,
            name: 'Standardsteuersatz',
            price: 3.5,
            rate: 0.2,
          },
        ],
      };
      cancellationItemsContentBlockClone.lineItems.push(
        {
          ..._.cloneDeep(dayProd),
          sku: 'P123123',
          header: 'Produkt 1',
          details: 'Produkt Details 1',
          sequence: 1,
        }
      );
    }

    offer.contentBlocks.splice(cancellationItemsContentBlockIndex, 1, cancellationItemsContentBlockClone);
  }

  const { totalPriceNet, totalPriceGross, totalTax, totalTaxes } = calculateTotals(offer);
  offer.totalPriceNet = totalPriceNet;
  offer.totalPriceGross = totalPriceGross;
  offer.totalTax = totalTax;
  offer.totalTaxes = totalTaxes;
  return offer;
};

const _htmlTemplatePdf = ({ cssStyles, body }: { cssStyles?: string | null; body: string }) => {
  return `
  <html>
    <head>
      <style>
        .pagebreak {
          break-inside: avoid;
        }

        tbody {page-break-inside: avoid; width: 100%;}

        ${cssStyles}
      </style>
    </head>
  <body>
    ${body}
  </body>
</html>`;
};

const _htmlTemplate = ({
  cssStyles,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  headerTemplate,
  footerTemplate,
  body,
}: {
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;
  cssStyles?: string | null;
  headerTemplate?: string | null;
  footerTemplate?: string | null;
  body: string;
}) => {
  return `
  <html>
  <head>
    <script>
      window.onload = function() {
        var wrapOuter = document.getElementById('wrap-outer')

        window.addEventListener("resize", resize);
        resize();

        function resize(){
          var width = document.documentElement.clientWidth || document.body.clientWidth;
          var scale = width/wrapOuter.getBoundingClientRect().width;
          wrapOuter.style.zoom = scale;
        }
      
      }
    </script>
    <style>
      del {
        background: #ffc6c4;
        margin-right: 5px;
      }

      ins {
        background: #D1FFBD;
        text-decoration: none;
      }

      body, html {
        padding: 0px;
        margin: 0px;
      }
        
      #wrap-outer {
        width: 210mm;
        position: absolute;
      }

      #wrap-inner {
        box-sizing: border-box;
        margin-top: ${marginTop}px;
        margin-bottom: ${marginBottom}px;
        margin-left: ${marginLeft}px;
        margin-right: ${marginRight}px;
      }

      #header {
        position: absolute;
        top: 0px;
      }

      #footer {
        position: absolute;
        bottom: 0px;
      }

      ${cssStyles || ''}
    </style>
  </head>
  <body>
  <div id="wrap-outer">
    <div id="header">
      ${headerTemplate || ''}
    </div>
  <div id="wrap-inner">
  
    ${body}
    </div>
    <div id="footer">
      ${footerTemplate || ''}
    </div>
    </div>
  </body>
</html>
  `;
};

function _formatOccupancySeating(seating: string) {
  if (seating === "UFORM") return "U-Form";
  if (seating === "BLOCK") return "Block";
  if (seating === "THEATER") return "Theater";
  if (seating === "PARLIAMENT") return "Parlament";
  if (seating === "BANKETT") return "Bankett";
  if (seating === "CIRCLE") return "Kreis";
  return ""
}

function _formatOccupancy(occupancy: string) {
  if (occupancy === 'MORNING') return 'Vormittag';
  if (occupancy === 'AFTERNOON') return 'Nachmittag';
  if (occupancy === 'FULLDAY') return 'Ganztägig';
  return '';
}

export function applyFormattingToInput(spaceId: number, template: OfferTemplateInput): OfferTemplateInputFormatted {
  const inputFormatted: OfferTemplateInputFormatted = {
    spaceId,
    ...template,
    expirationDateFormatted: _formatDate(template.expirationDate),
    dateFormatted: _formatDate(template.date),
    contentBlocks: (template.contentBlocks || []).map(cb => ({
      ...cb,
      inputDay: {
        day: cb.inputDay?.day || 0,
        totalGuests: cb.inputDay?.totalGuests || 0,
        overnightGuests: cb.inputDay?.overnightGuests || 0,
        occupancy: (cb.inputDay?.occupancy || []).map(o => ({
          ...o,
          room: o.room || 0,
          occupancy: o.occupancy || '',
          occupancyFormatted: _formatOccupancy(o.occupancy || ''),
          seating: o.seating || '',
          seatingFormatted: _formatOccupancySeating(o.seating || ''),
        })),
        addons: (cb.inputDay?.addons || []).map(a => ({
          ...a,
          sku: a.sku || '',
          header: a.header || '',
          details: a.details || '',
          count: a.count || 0,
        })),
        dayFormatted: formatOfferDay(cb.inputDay?.day || 0),
        date: moment(template.startDate)
          .add(cb.inputDay?.day || 0, 'days')
          .toDate(),
        dateFormatted: _formatDate(
          moment(template.startDate)
            .add(cb.inputDay?.day || 0, 'days')
            .toDate(),
        ),
      },
      lineItems: (cb.lineItems || []).filter(li => template.includeEmptyLineItems || li.priceNet !== 0).map(li => ({
        ...li,
        dayFormatted: formatOfferDay(li.day),
        date: moment(template.startDate).add(li.day, 'days').toDate(),
        dateFormatted: _formatDate(moment(template.startDate).add(li.day, 'days').toDate()),
        priceItemFormatted: _formatPrice(li.priceItem, template.priceList),
        priceItemGross: li.priceGross / (li.count || 1),
        priceItemGrossFormatted: _formatPrice(li.priceGross / (li.count || 1), template.priceList),
        priceItemNet: li.priceNet / (li.count || 1),
        priceItemNetFormatted: _formatPrice(li.priceNet / (li.count || 1), template.priceList),
        priceNetFormatted: _formatPrice(li.priceNet, template.priceList),
        priceGrossFormatted: _formatPrice(li.priceGross, template.priceList),
        components: (li.components || []).map(c => ({
          ...c,
          priceFormatted: _formatPrice(c.price),
        })),
        taxes: (li.taxes || []).map(t => ({
          ...t,
          rateFormatted: t.rate.toFixed(2),
          ratePercent: t.rate * 100.0,
          ratePercentFormatted: `${(t.rate * 100.0).toFixed(2)}%`,
          priceFormatted: _formatPrice(t.price),
        })),
      })),
      cancellationRule: cb.cancellationRule ? {
        ...cb.cancellationRule,
        daysToEventFormatted: cb.cancellationRule.daysToEvent.toFixed(0),
        rateFormatted: `${(cb.cancellationRule.rate * 100.0).toFixed(2)}%`,
      } : cb.cancellationRule
    })),
    totalPriceNetFormatted: _formatPrice(template.totalPriceNet, template.priceList),
    totalPriceGrossFormatted: _formatPrice(template.totalPriceGross, template.priceList),
    agreedCancellationWaiveGrossFormatted: template.agreedCancellationWaiveGross ? _formatPrice(template.agreedCancellationWaiveGross, template.priceList) : null,
    agreedCancellationWaiveNetFormatted: template.agreedCancellationWaiveNet ? _formatPrice(template.agreedCancellationWaiveNet, template.priceList) : null,
    manualCancellationWaiveGrossFormatted: template.manualCancellationWaiveGross ? _formatPrice(template.manualCancellationWaiveGross, template.priceList) : null,
    manualCancellationWaiveNetFormatted: template.manualCancellationWaiveNet ? _formatPrice(template.manualCancellationWaiveNet, template.priceList) : null,
    totalTaxFormatted: _formatPrice(template.totalTax, template.priceList),
    totalTaxes: (template.totalTaxes || []).map(t => ({
      ...t,
      rateFormatted: t.rate.toFixed(2),
      ratePercent: t.rate * 100.0,
      ratePercentFormatted: `${(t.rate * 100.0).toFixed(2)}%`,
      priceFormatted: _formatPrice(t.price),
    })),
  };
  return inputFormatted;
}

export async function generateHtmlOffer(inputFormatted: OfferTemplateInputFormatted, resolveMediaSrc?: TResolveMediaSrc | null): Promise<string> {
  const renderedContentBlocks = await Promise.all(
    inputFormatted.contentBlocks.map(async (cb, index) => {
      try {
        const renderedBlock = await handlebars(
          cb.template,
          {
            ...inputFormatted,
            contentBlock: {
              ...cb,
              formFields: await cb.formFields?.reduce(
                async (obj, item) => ({
                  ...(await obj),
                  [item.name]: await handlebars(item.defaultValue || item.value || '', inputFormatted, resolveMediaSrc),
                }),
                {},
              ),
            },
            lineItems: cb.lineItems,
            inputDay: cb.inputDay,
            cancellationRule: cb.cancellationRule
          },
          resolveMediaSrc,
        );
        return renderedBlock;
      } catch (e: any) {
        throw new Error(`Fehler in Block #${cb.sequence}: ` + e.message);
      }
    }),
  );
  return _htmlTemplate({
    cssStyles: inputFormatted.pageOptions.cssStyles,
    marginBottom: inputFormatted.pageOptions.marginBottom,
    marginLeft: inputFormatted.pageOptions.marginLeft,
    marginRight: inputFormatted.pageOptions.marginRight,
    marginTop: inputFormatted.pageOptions.marginTop,
    headerTemplate: await handlebars(inputFormatted.pageOptions.headerTemplate || '', inputFormatted, resolveMediaSrc),
    footerTemplate: await handlebars(inputFormatted.pageOptions.footerTemplate || '', inputFormatted, resolveMediaSrc),
    body: renderedContentBlocks.join(''),
  });
}

export async function generateHtmlOfferHeaderForPdf(
  inputFormatted: OfferTemplateInputFormatted,
  resolveMediaSrc?: TResolveMediaSrc | null,
): Promise<string> {
  return await handlebars(inputFormatted.pageOptions.headerTemplate || '', inputFormatted, resolveMediaSrc);
}

export async function generateHtmlOfferFooterForPdf(
  inputFormatted: OfferTemplateInputFormatted,
  resolveMediaSrc?: TResolveMediaSrc | null,
): Promise<string> {
  return await handlebars(inputFormatted.pageOptions.footerTemplate || '', inputFormatted, resolveMediaSrc);
}

export async function generateHtmlOfferForPdf(
  inputFormatted: OfferTemplateInputFormatted,
  resolveMediaSrc?: TResolveMediaSrc | null,
): Promise<string> {
  const renderedContentBlocks = await Promise.all(
    inputFormatted.contentBlocks.map(async (cb, index) => {
      try {
        const renderedBlock = await handlebars(
          cb.template,
          {
            ...inputFormatted,
            contentBlock: {
              ...cb,
              formFields: await cb.formFields?.reduce(
                async (obj, item) => ({
                  ...(await obj),
                  [item.name]: await handlebars(item.defaultValue || item.value || '', inputFormatted, resolveMediaSrc),
                }),
                {},
              ),
            },
            lineItems: cb.lineItems,
            inputDay: cb.inputDay,
            cancellationRule: cb.cancellationRule
          },
          resolveMediaSrc,
        );
        //if (cb.type === "CUSTOM_FORM") console.log("block", JSON.stringify(cb.formFields), cb.template, renderedBlock)
        return renderedBlock;
      } catch (e: any) {
        throw new Error(`Fehler in Block #${cb.sequence}: ` + e.message);
      }
    }),
  );
  console.log('generateHtmlOfferForPdf', )
  return _htmlTemplatePdf({
    cssStyles: inputFormatted.pageOptions.cssStyles,
    body: renderedContentBlocks.join(''),
  });
}
