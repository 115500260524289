import { gql } from '__generated__/gql';
import { ApolloCache } from '@apollo/client';

export const LOG_EMAILHISTORY_SHORT_FRAGMENT = gql(`
  fragment EmailHistoryOutputShort on EmailHistoryOutput {
    id
    createdAt
    hotelRef
    offerRef
    offer { id refCode }
    offerVersionRef
    offerVersion { id version }
    userName
    templateName
    from
    to
    subject
    err
    success
    space { ...SpaceShort }
  }
`);

export const HOTEL_LIST_SHORT_FRAGMENT = gql(`
  fragment HotelListShort on HotelListOutput {
    id
    name
    refCode
    slug
    extRefCode
    businessCountry
    widgetTestUrl
    widgetStatus
    listerUrl
    listerStatus
    space { ...SpaceShort }
    integrations { id secret { id name system } }
  }
`);

export const HOTEL_LIST_DROPDOWN_FRAGMENT = gql(`
  fragment HotelListDropdown on HotelListOutput {
    id
    name
    slug
    refCode
    extRefCode
    businessCountry
    space { ...SpaceShort }
    serviceTypes { forLister forWidget serviceType { id name sku } }
  }
`);

export const HOTEL_LIST_VIEW_FRAGMENT = gql(`
  fragment HotelListView on HotelListOutput {
    id
    name
    slug
    refCode
    extRefCode
    rating
    businessName
    businessAddress1
    businessAddress2
    businessAddress3
    businessAddress4
    businessCountry
    businessEmail
    notificationEmail
    widgetUrl
    widgetColor
    widgetColorEnabled
    widgetPrivacyUrl
    widgetTermsUrl
    widgetNoRooms
    widgetExpandedMode
    widgetSlimMode
    widgetTestUrl
    widgetStatus
    widgetBaseUrl
    listerUrl
    listerStatus
    offerNotApprovedReminderHotel
    offerNotApprovedReminderClient
    depositReminderHotel
    depositReminderCheckHotel
    enableAvailabilityCheck
    offerExpirationDays
    locationLatitude
    locationLongitude
    showInLister
    showInWidget
    medias { id name previewUrl }
    meetingRooms { 
      id 
      name 
      area
      capacityUForm
      capacityTheater
      capacityParlament
      capacityCircle
      capacityBankett
      capacityCocktail
      capacityBlock
    }
    attributes { id attribute }
    cancellationRules {
      id
      daysToEvent
      minCapacity
      maxCapacity
      minOvernight
      maxOvernight
      minTotalGuests
      maxTotalGuests
      toleranceRate
      rate
    }
    texts {
      id
      details
      language
      type
    }
    offerTemplate { id name }
    space { ...SpaceShort }
    integrations { id secret { id name system } }
    serviceTypes { forLister forWidget serviceType { id name sku } }
  }
`);

export const COORDINATES_BY_ADDRESS_QUERY = gql(`
  query CoordinatesByAddress($address: String!) {
    coordinatesByAddress(address: $address) {
      lat
      lng
    }
  }
`);

export const HOTEL_LIST_QUERY = gql(`
  query ListHotels($spaceId: Int, $hotelId: Int, $skip: Int, $take: Int) {
    listHotels(spaceId: $spaceId, hotelId: $hotelId, skip: $skip, take: $take) {
      ...HotelListShort
    }
  }
`);

export const HOTEL_LIST_STATUS_QUERY = gql(`
  query ListHotelsStatus($spaceId: Int) {
    listHotels(spaceId: $spaceId, skip: 0, take: 100) @connection(key: "listHotelsStatus", filter: ["spaceId"]) {
      id
      name
      refCode
      widgetStatus
      listerStatus
    }
  }
`);

export const HOTEL_LIST_DROPDOWN_QUERY = gql(`
  query ListHotelsDropdown($spaceId: Int) {
    listHotels(spaceId: $spaceId, skip: 0, take: 100) @connection(key: "listHotelsDropdown", filter: ["spaceId"]) {
      ...HotelListDropdown
    }
  }
`);

export const HOTEL_VIEW_QUERY = gql(`
  query ViewHotel($id: Int!) {
    viewHotel(id: $id) {
      ...HotelListView
    }
  }
`);

export const CREATE_HOTEL_MUTATION = gql(`
  mutation CreateHotel($spaceId: Int!, $data: HotelCreateInput!) {
    createHotel(spaceId: $spaceId, data: $data) {
      ...HotelListView
    }
  }
`);

export const UPDATE_HOTEL_MUTATION = gql(`
  mutation UpdateHotel($id: Int!, $data: HotelInput!) {
    updateHotel(id: $id, data: $data) {
      ...HotelListView
    }
  }
`);

export const DELETE_HOTEL_MUTATION = gql(`
  mutation DeleteHotel($id: Int!) {
    deleteHotel(id: $id)
  }
`);

export const COPY_HOTEL_MUTATION = gql(`
  mutation CopyHotel($id: Int!) {
    copyHotel(id: $id) {
      ...HotelListView
    }
  }
`);

export const EVICT_HOTELS_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listHotels' });
  cache.gc();
};

export const REFETCH_HOTELS_QUERIES = (hotelId?: number | undefined) => [
  ...(hotelId
    ? [
        {
          query: HOTEL_VIEW_QUERY,
          variables: { id: hotelId },
        },
      ]
    : []),
];

export const SUDO_MUTATION = gql(`
  mutation SudoMutation($id: Int!) {
    sudo(id: $id) {
      success
      user { 
        ...UserFull
      }
    }
  }
`);

export const SEM_PLAN_FRAGMENT = gql(`
  fragment SEMPlanFull on SEMPlan {
    id
    plan
    label
    comment
    flags {
      maxUsers
      maxSeminarRooms
      maxOfferTemplates
      multiTenancy
      adFree
    }
  }
`);

export const USER_FULL_FRAGMENT = gql(`
  fragment UserFull on User {
    id
    createdAt
    updatedAt
    name
    username
    email
    isAdmin
    isLocked
    lastSeen
    space {
      id
      name
      country
      activeSEMPlan { id semPlan { ...SEMPlanFull } }
    }
    memberships {
      isAdmin
      space { ...SpaceShort }
    }
  }
`);

export const USERS_LIST_QUERY = gql(`
  query ListUsers($spaceId: Int) {
    listUsers(spaceId: $spaceId) {
      ...UserFull
    }
  }
`);

export const USER_VIEW_QUERY = gql(`
  query ViewUser($id: Int!) {
    viewUser(id: $id) {
      ...UserFull
    }
  }
`);

export const CREATE_USER_MUTATION = gql(`
  mutation CreateUser($spaceId: Int, $data: UserCreateInput!) {
    createUser(spaceId: $spaceId, data: $data) {
      ...UserFull
    }
  }
`);

export const UPDATE_USER_MUTATION = gql(`
  mutation UpdateUser($id: Int!, $data: UserUpdateInput!) {
    updateUser(id: $id, data: $data) {
      ...UserFull
    }
  }
`);

export const RESET_USERPASSWORD_MUTATION = gql(`
  mutation ResetUserPassword($id: Int!) {
    resetUserPassword(id: $id)
  }
`);

export const CHANGE_USERPASSWORD_MUTATION = gql(`
  mutation ChangeUserPassword($id: Int, $password: String, $passwordResetCode: String, $newPassword: String!) {
    changeUserPassword(id: $id, password: $password, passwordResetCode: $passwordResetCode, newPassword: $newPassword)
  }
`);

export const EVICT_USERS_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listUsers' });
  cache.evict({ fieldName: 'listSpaces' });
  cache.gc();
};

export const REFETCH_USERS_QUERIES = (userId?: number | undefined) => [];

export const SPACE_FULL_FRAGMENT = gql(`
  fragment SpaceFull on Space {
    id
    createdAt
    updatedAt
    name
    country
    users { id name email }
    members { isAdmin user { id name email } }
    parent { id name country }
    children { id name country }
    activeSEMPlan { id validFrom validTo semPlan { id plan label } }
  }
`);

export const SPACE_SHORT_FRAGMENT = gql(`
  fragment SpaceShort on Space {
    id
    name
    country
  }
`);

export const EVICT_SPACES_QUERIES = (cache: ApolloCache<any>) => {
  EVICT_USERS_QUERIES(cache);
};

export const REFETCH_SPACES_QUERIES = (spaceId?: number | undefined) => [...REFETCH_USERS_QUERIES()];

export const SEMPLANS_LIST_QUERY = gql(`
  query ListSEMPlans {
    listSEMPlans {
      ...SEMPlanFull
    }
  }
`);

export const SPACES_LIST_QUERY = gql(`
  query ListSpaces {
    listSpaces {
      ...SpaceFull
    }
  }
`);

export const SPACE_VIEW_QUERY = gql(`
  query ViewSpace($id: Int!) {
    viewSpace(id: $id) {
      ...SpaceFull
    }
  }
`);

export const CREATE_SPACE_MUTATION = gql(`
  mutation ProvisionSpace($data: ProvisionSpaceInput!) {
    provisionSpace(data: $data) {
      space { ...SpaceShort }
      user { id name email username }
      initialPassword
    }
  }
`);

export const UPDATE_SPACE_MUTATION = gql(`
  mutation UpdateSpace($id: Int!, $data: SpaceUpdateInput!) {
    updateSpace(id: $id, data: $data) {
      ...SpaceFull
    }
  }
`);

export const IMPORT_DEFAULTOFFERTEMPLATES_MUTATION = gql(`
  mutation ImportDefaultOfferTemplates($targetSpaceId: Int!) {
    importDefaultOfferTemplates(targetSpaceId: $targetSpaceId) {
      id name
    }
  }
`);

export const IMPORT_DEFAULTEMAILTEMPLATES_MUTATION = gql(`
  mutation ImportDefaultEmailTemplates($targetSpaceId: Int!) {
    importDefaultEmailTemplates(targetSpaceId: $targetSpaceId, overwrite: true) {
      id usage
    }
  }
`);

export const SPACE_SETTINGS_CI_QUERY = gql(`
  query QuerySpaceSettingsCi($spaceId: Int!) {
    querySpaceSettingsCi(spaceId: $spaceId) {
      spaceId
      logoFileName
      logoFileNameEnabled
      primaryColor
      primaryColorEnabled
      signature
      signatureEnabled
    }
  }
`);

export const UPDATE_SPACE_SETTINGS_CI_MUTATION = gql(`
  mutation UpdateSpaceSettingsCi($data: SpaceSettingsCiInput!) {
    updateSpaceSettingsCi(data: $data) {
      spaceId
      logoFileName
      logoFileNameEnabled
      primaryColor
      primaryColorEnabled
      signature
      signatureEnabled
    }
  }
`);
