import React from 'react';
import { createBrowserRouter, useParams } from 'react-router-dom';

import App from './main';
import Dashboard from 'pages/dashboard';
import Products from 'pages/products/index';
import ProductBundlesList from 'pages/products/servicetypes';
import Pricelists from 'pages/prices/index';
import AvailabilityIndex from 'pages/availability/index';
import Offers from 'pages/offers/index';
import Invoices from 'pages/invoices/index';
import Security from 'pages/settings/security/index';
import Space from 'pages/settings/space/index';
import SettingsHotelsIndex from 'pages/settings/hotels';
import SettingsIntegrationIndex from 'pages/settings/integration';
import Login from 'layout/login';
import ErrorPage from 'pages/error';
import ContentProductsList from 'pages/content/products';
import MediaList from 'pages/content/media';
import ContentTemplatesIndex from 'pages/content/templates';
import TasksBoard from 'pages/tasks/tasks';
import TasksIndex from 'pages/tasks';
import TasksSettingsIndex from 'pages/tasks/tasks_settings';
import ClientsIndex from 'pages/clients';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: 'products/*',
        element: <Products />,
      },
      {
        path: 'servicetypes/*',
        element: <ProductBundlesList />,
      },
      {
        path: 'offers/*',
        element: <Offers />,
      },
      {
        path: 'tasks/*',
        element: <TasksIndex />,
      },
      {
        path: 'tasks/settings/*',
        element: <TasksSettingsIndex />,
      },
      {
        path: 'clients/*',
        element: <ClientsIndex />,
      },
      {
        path: 'invoices/*',
        element: <Invoices />,
      },
      {
        path: 'content/texts/*',
        element: <ContentProductsList />,
      },
      {
        path: 'content/media/*',
        element: <MediaList />,
      },
      {
        path: 'content/templates/*',
        element: <ContentTemplatesIndex />,
      },
      {
        path: 'pricelists/*',
        element: <Pricelists />,
      },
      {
        path: 'availability/*',
        element: <AvailabilityIndex />,
      },
      {
        path: 'settings/hotels/*',
        element: <SettingsHotelsIndex />,
      },
      {
        path: 'settings/security/*',
        element: <Security />,
      },
      {
        path: 'settings/integration/*',
        element: <SettingsIntegrationIndex />,
      },
      {
        path: 'settings/space/*',
        element: <Space />,
      },
      {
        path: 'error',
        element: <ErrorPage />,
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/forgotpassword/:passwordResetCode',
    Component: () => <Login passwordResetCode={useParams().passwordResetCode!} />,
  },
]);

export default router;
