import { gql } from '__generated__/gql';
import { ApolloCache } from '@apollo/client';
import { EVICT_OFFER_QUERIES, REFETCH_OFFER_QUERIES } from 'pages/offers/gql';

export const INVOICE_EVENT_DETAILS_FRAGMENT = gql(`
  fragment InvoiceEventDetailsOutput on AMEventOutput {
    id
    attendances {
      id
      serviceDirectPay
      roomDirectPay
      extraDirectPay
      parkingDirectPay      
      profile {
        id
        firstname
        lastname
      }
      days {
        id
        day
        attending
        forenoon
        morning
        lunch
        afternoon
        dinner
        room
        morningShow
        forenoonShow
        lunchShow
        afternoonShow
        dinnerShow
        roomShow
        serviceDirectPay
        roomDirectPay
        extraDirectPay
        parkingDirectPay
      }      
    }
  }
`);

export const INVOICE_VIEW_QUERY = gql(`
  query ViewInvoice($id: Int!) {
    viewWlOffer(id: $id) {
      ...WLOfferListView
    }
    viewWlOfferInvoiceVersion(offerId: $id) {
      ...WLOfferVersionListView
    }
    viewWlOfferInvoiceEvent(offerId: $id) {
      ...InvoiceEventDetailsOutput
    }
    invoiceQuickPriceDehydrate(offerId: $id)
    invoiceProductTextDehydrate(offerId: $id)
  }
`);

export const INVOICE_VIEW_MEDIA_QUERY = gql(`
  query ViewInvoiceMedia($mediaId: Int!) {
    readWlOfferInvoiceMediaBase64(mediaId: $mediaId)
  }
`);

export const INVOICE_APPROVE_HOTEL_MUTATION = gql(`
  mutation ApproveInvoiceHotel($offerVersionId: Int!, $attendances: [WLInvoiceAttendanceInput!], $extraLineItems: [WLOfferVersionContentBlockLineItemInput!]) {
    approveInvoiceHotel(offerVersionId: $offerVersionId, attendances: $attendances, extraLineItems: $extraLineItems) {
      ...WLOfferVersionListView
    }
  }
`);

export const EVICT_INVOICE_QUERIES = (cache: ApolloCache<any>) => {
  EVICT_OFFER_QUERIES(cache)
};

export const REFETCH_INVOICE_QUERIES = (offerId?: number | undefined, offerVersionId?: number | undefined) => [
  ...(offerId
    ? [
        {
          query: INVOICE_VIEW_QUERY,
          variables: { id: offerId },
        },
      ]
    : []),
  ...REFETCH_OFFER_QUERIES(offerId, offerVersionId)
];
