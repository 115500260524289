import { gql } from '__generated__/gql';
import { ApolloCache } from '@apollo/client';

export const OFFERVERSION_LIST_SHORT_FRAGMENT = gql(`
  fragment WLOfferVersionListShort on WLOfferVersionListOutput {
    id
    createdAt
    updatedAt
    version
    extRefCode
    status
    type
    offerDate
    startDate
    offer {
      id
      refCode
      status
      deleteReason
    }
    hotel {
      id
      name
      spaceId
      integrations { id secret { id system } }
    }
    space { ...SpaceShort }
    input {
      startDate
      endDate
    }
    client {
      firstname
      lastname
      company
    }
  }
`);

export const OFFERVERSION_DIFF_FRAGMENT = gql(`
  fragment WLOfferVersionDiffOutput on WLOfferVersionListOutput {
    diffToApproved {
      requiresApproval
      startDateChanged { fromStartDate toStartDate }
      diff {
        day
        diffs {
          sku
          header
          count1
          count2
          diff
          requiresApproval
        }
      }
    }
  }
`);

export const OFFERVERSION_LIST_VIEW_FRAGMENT = gql(`
  fragment WLOfferVersionListView on WLOfferVersionListOutput {
    id
    createdAt
    updatedAt
    version
    extRefCode
    extRefData
    status
    offerDate
    startDate
    title
    type
    filename
    cssStyles
    marginTop
    marginBottom
    marginLeft
    marginRight
    includeEmptyLineItems
    headerTemplate
    footerTemplate
    agreedCancellationWaiveNet
    manualCancellationWaiveNet
    totalPriceNet
    totalPriceGross
    additionalInfo1
    additionalInfo2
    additionalInfo3
    space { ...SpaceShort }
    hotel {
      id
      name
      refCode
      spaceId
      businessName
      businessAddress1
      businessAddress2
      businessAddress3
      integrations { id secret { id system } }
      offerExpirationDays
    }
    offer {
      id
      language
      status
    }
    input {
      language
      startDate
      endDate
      prevdayGuests
      comment
      serviceType {
        sku
        name
        header
        details
      }
      days {
        day
        addons {
          count
          sku
          header
          product { id name }
          facility { id name }
        }
        occupancy {
          room
          occupancy
          seating
        }
        overnightGuests
        totalGuests
      }
    }
    priceList {
      id
      name
      currency
      isPricesNet
    }
    client {
      id
      firstname
      lastname
      company
      email
      address
      city
      zip
      country
      phone
      billingCompany
      billingFirstname
      billingLastname
      billingAddress
      billingCity
      billingZip
      billingCountry
    }
    contentBlocks {
      id
      type
      template
      sequence
      blockDescription
      formFields {
        id
        type
        name
        label
        value
        sequence
        required
      }
      cancellationRule {
        id
        daysToEvent
        rate
      }
      lineItems {
        id
        day
        sku
        spaceId
        header
        details
        count
        priceItem
        priceNet
        priceGross
        sequence
        media {
          id
          name
          name
          mimeType
        }
        components {
          price
          type {
            id
            name
          }
        }
        taxes {
          price
          type {
            id
            name
          }
          rate {
            id
            rate
          }
        }
      }
    }
    taxes {
      price
      type {
        id
        name
      }        
      rate {
        id
        rate
      }
    }
  }
`);

export const OFFER_HISTORY_LIST_SHORT_FRAGMENT = gql(`
  fragment WLOfferHistoryListShort on WLOfferHistoryListOutput {
    id
    createdAt
    status
    extraInfo
    offerVersion {
      id
      version
      status
    }
    user {
      id
      name
    }
  }
`);

export const OFFER_LIST_SHORT_FRAGMENT = gql(`
  fragment WLOfferListShort on WLOfferListOutput {
    id
    createdAt
    updatedAt
    status
    source
    language
    refCode
    deleteReason
    space { ...SpaceShort }
    hotel {
      id
      name
      spaceId
      integrations { id }
    }
  }
`);

export const OFFER_LIST_VIEW_FRAGMENT = gql(`
  fragment WLOfferListView on WLOfferListOutput {
    id
    createdAt
    status
    source
    language
    refCode
    space { ...SpaceShort }
    hotel {
      id
      name
      spaceId
      integrations { id }
    }
    history {
      ...WLOfferHistoryListShort
    }
    emailHistory {
      ...EmailHistoryOutputShort
    }
  }
`);

export const OFFERVERSIONS_LIST_QUERY = gql(`
  query ListWlOfferVersions($spaceId: Int, $hotelId: Int, $offerSource: EOfferSource!, $versionType: [EOfferVersionType!], $versionStatus: [EOfferVersionStatus!], $offerStatus: [EOfferStatus!]) {
    listWlOfferVersions(spaceId: $spaceId, hotelId: $hotelId, offerSource: $offerSource, versionType: $versionType, versionStatus: $versionStatus, offerStatus: $offerStatus) {
      ...WLOfferVersionListShort
    }
  }
`);

export const OFFER_VIEW_QUERY = gql(`
  query ViewWlOffer($id: Int!) {
    viewWlOffer(id: $id) {
      ...WLOfferListView
    }
    viewWlOfferLatestVersion(offerId: $id) {
      ...WLOfferVersionListView
      ...WLOfferVersionDiffOutput
    }
  }
`);

export const OFFER_AND_VERSION_VIEW_QUERY = gql(`
  query ViewWlOfferAndVersion($id: Int!, $versionId: Int!) {
    viewWlOffer(id: $id) {
      ...WLOfferListView
    }
    viewWlOfferVersion(id: $versionId) {
      ...WLOfferVersionListView
      ...WLOfferVersionDiffOutput
    }
  }
`);

export const OFFERVERSION_VIEW_QUERY = gql(`
  query ViewWlOfferVersion($id: Int!) {
    viewWlOfferVersion(id: $id) {
      ...WLOfferVersionListView
    }
  }
`);

export const OFFERVERSION_QUICKPRICE_QUERY = gql(`
  query OfferVersionQuickPriceDehydrate($id: Int!) {
    offerVersionQuickPriceDehydrate(id: $id)
  }
`);

export const OFFERVERSION_PRODUCTTEXT_QUERY = gql(`
  query OfferVersionProductTextDehydrate($id: Int!) {
    offerVersionProductTextDehydrate(id: $id)
  }
`);

export const OFFERVERSION_UPSERT_MUTATION = gql(`
  mutation UpsertOfferVersion($id: Int, $data: WLOfferVersionInput!) {
    upsertOfferVersion(id: $id, data: $data) {
      ...WLOfferVersionListView
    }
  }
`);

export const OFFERVERSION_APPROVE_MUTATION = gql(`
  mutation ApproveWlOfferVersion($offerVersionId: Int!) {
    approveOfferVersion(offerVersionId: $offerVersionId) {
      ...WLOfferVersionListView
    }
  }
`);

export const OFFERVERSION_REJECT_MUTATION = gql(`
  mutation RejectWlOfferVersion($offerVersionId: Int!) {
    rejectOfferVersion(offerVersionId: $offerVersionId)
  }
`);

export const OFFERVERSION_PROCESSEVENTSTARTED_MUTATION = gql(`
  mutation ProcessOfferVersionEventStarted($offerVersionId: Int!) {
    processOfferVersionEventStarted(offerVersionId: $offerVersionId)
  }
`);

export const OFFERVERSION_PROCESSEVENTENDED_MUTATION = gql(`
  mutation ProcessOfferVersionEventEnded($offerVersionId: Int!) {
    processOfferVersionEventEnded(offerVersionId: $offerVersionId)
  }
`);

export const OFFERVERSION_DELETE_MUTATION = gql(`
  mutation DeleteWlOfferVersion($offerVersionId: Int!, $deleteReason: String!) {
    deleteOfferVersion(offerVersionId: $offerVersionId, deleteReason: $deleteReason)
  }
`);

export const OFFER_SEND_REMINDER_CLIENT_MUTATION = gql(`
  mutation SendOfferReminderEmailClient($offerVersionId: Int!) {
    sendOfferReminderEmailClient(offerVersionId: $offerVersionId)
  }
`);

export const OFFER_CREATE_MUTATION = gql(`
  mutation CreateOffer($hotelId: Int!, $source: EOfferSource!, $input: QuickPriceInput!, $client: QuickOfferClient!) {
    createOffer(hotelId: $hotelId, source: $source, input: $input, client: $client) {
      ...WLOfferListShort
    }
  }
`);

export const OFFER_PREVIEW_HTML_QUERY = gql(`
  query OfferQuickInputHtmlPreview($hotelId: Int!, $source: EOfferSource!, $input: QuickPriceInput!, $client: QuickOfferClient!) {
    offerQuickInputHtmlPreview(hotelId: $hotelId, source: $source, input: $input, client: $client) {
      html
    }
  }
`);

export const QUICKPRICE_DEHYDRATE_QUERY = gql(`
  query QuickPriceDehydrate($hotelId: Int!) {
    quickPriceDehydrate(hotelId: $hotelId, source: LISTER)
  }
`);

// TODO das ist falsch
export const EVICT_OFFER_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({
    fieldName: 'listWlOffers({"status":["APPROVED","REJECTED"]})',
  });
  cache.evict({
    fieldName: 'listWlOffers({"status":["CLIENTVERIFICATION","PENDING"]})',
  });
  cache.evict({ fieldName: 'listWlOffers({"status":["DELETED"]})' });
  cache.gc();
};

export const REFETCH_OFFER_QUERIES = (offerId?: number | undefined, offerVersionId?: number | undefined) => [
  ...(offerId
    ? [
        {
          query: OFFER_VIEW_QUERY,
          variables: { id: offerId },
        },
      ]
    : []),
  ...(offerVersionId
    ? [
        {
          query: OFFERVERSION_VIEW_QUERY,
          variables: { id: offerVersionId },
        },
      ]
    : []),
];
